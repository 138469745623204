import React from 'react';

const FeeComponent = ({ title, fee, type }) => {
  const getTypeSymbol = () => {
    if (type === "dollar") return " USDT";
    if (type?.toLowerCase() == "percentage") return " %";
    return "";
  };

  return (
    <div className="col-lg-3 col-md-6 py-2 col-sm-6">
      <p className="py-1 mb-1">
        <b style={{ fontWeight: "700" }}>{title}</b>
      </p>
      <h5
        style={{
          textTransform: "capitalize",
          fontWeight: "400",
        }}
        className="currentPrice py-2 mb-2"
      >
        {fee}
        {getTypeSymbol()}
      </h5>
    </div>
  );
};

export default FeeComponent;
