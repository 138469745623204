import React, { useState, useRef } from 'react';
import { Autocomplete, GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';

const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '400px',
};
const defaultCenter = { lat: 37.7749, lng: -122.4194 }; // Default to San Francisco

const LocationSearch = ({ onCoordinatesChange }) => {
  const [location, setLocation] = useState('');
  const [coordinates, setCoordinates] = useState(defaultCenter);
  const autocompleteRef = useRef(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCYx6E7TSAHDQMFB41DNksmX7v3zXaKt5Y', // Replace with your API key
    libraries,
  });

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (place && place.geometry) {
      const newCoordinates = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      const locationName = place.formatted_address;
      setLocation(locationName);
      setCoordinates(newCoordinates);

      // Pass both location name and coordinates to the parent
      onCoordinatesChange({ location: locationName, coordinates: newCoordinates });
    }
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="container mt-5">
      <h3 className="mb-4">Search Location</h3>
      <Autocomplete
        onLoad={(ref) => (autocompleteRef.current = ref)}
        onPlaceChanged={handlePlaceSelect}
      >
        <input
          type="text"
          className="form-control"
          placeholder="Enter a location"
        />
      </Autocomplete>

      {location && (
        <div className="py-4">
          <h5>Selected Location:</h5>
          <p><strong>Address:</strong> {location}</p>
        </div>
      )}

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={coordinates}
      >
        <Marker position={coordinates} />
      </GoogleMap>
    </div>
  );
};

export default LocationSearch;


const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;