import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { Button } from '../../../../shared/components/Button';

const GuideInstructionModal = ({ show, hide }) => {
    return (
        <Modal size="xl" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={hide}>
            <Modal.Header closeButton>
                <Modal.Title className="text-center" style={{ fontSize: 'large' }}>
                    <b>Guide: Creating Bot, Channel, and Username</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container padding-bottom-3x p-0">
                    <div className="card gap-2 mt-0 p-0" style={{ boxShadow: 'none', fontFamily: 'sans-serif' }}>
                        <InstructionSection>
                            <h5>How to Create a Bot and Get Bot Token</h5>
                            <Stepper>
                                <Step>
                                    <StepNumber>1</StepNumber>
                                    <StepContent>
                                        Open Telegram and search for <b>BotFather</b>.
                                        <StepImage
                                            src='./img/step1.jpeg'
                                            className='d-flex col-12 col-md-6 '
                                        />
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>2</StepNumber>
                                    <StepContent>
                                        Start a chat with BotFather by clicking <b>Start</b> or sending the <code>/start</code> command.
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>3</StepNumber>
                                    <StepContent>
                                        Send the <code>/newbot</code> command to BotFather.
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>4</StepNumber>
                                    <StepContent>
                                        Provide a name for your bot when prompted (e.g., <b>MyCustodiyOtpBot</b>).
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>5</StepNumber>
                                    <StepContent>
                                        Choose a unique username for your bot, ending with <b>bot</b> (e.g., <b>MyFirstOTPBot</b>).
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>6</StepNumber>
                                    <StepContent>
                                        Once created, BotFather will provide an HTTP API token (e.g., <code>123456789:ABCdefGHIjklMNOpqrSTUvwxYZ12345</code>). Copy this token and add it to Custodiy.

                                        <StepImage
                                            src='./img/step6.jpeg'
                                            className='d-flex col-12 col-md-4 '
                                        />

                                    </StepContent>
                                </Step>
                            </Stepper>
                        </InstructionSection>

                        <InstructionSection>
                            <h5>How to Create a Channel and Get Channel ID</h5>
                            <Stepper>
                                <Step>
                                    <StepNumber>1</StepNumber>
                                    <StepContent>
                                        Open Telegram and click the <b>Menu</b> (three horizontal lines) or the <b>+</b> button.
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>2</StepNumber>
                                    <StepContent>
                                        Select <b>New Channel</b>.

                                        <StepImage
                                            src='./img/channel-step2.jpeg'
                                            className='d-flex col-12 col-md-6 '
                                        />

                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>3</StepNumber>
                                    <StepContent>
                                        Enter the following:
                                        <ul>
                                            <li>A Channel Name (e.g., <b>My Channel</b>).</li>
                                            <li>Choose the Channel Type: <b>Private</b>.</li>
                                        </ul>
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>4</StepNumber>
                                    <StepContent>
                                        Complete the setup and save.
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>5</StepNumber>
                                    <StepContent>
                                        Search for <b>RawDataBot</b> in contacts and open it or click on direct link <a target='_blank' href='https://t.me/raw_data_bot'>RawDataBot</a>.

                                        <StepImage
                                            src='./img/channel-step5.jpeg'
                                            className='d-flex col-12 col-md-6 '
                                        />

                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>6</StepNumber>
                                    <StepContent>
                                        Click on your channel in RawDataBot, and it will display the Channel ID (e.g., <code>-1001234567890</code>). Copy this ID and paste it into Custodiy.

                                        <StepImage
                                            src='./img/channel-step6.jpeg'
                                            className='d-flex col-12 col-md-6 '
                                        />

                                    </StepContent>
                                </Step>
                            </Stepper>
                        </InstructionSection>

                        <InstructionSection>
                            <h5>Final Configuration of the Channel with the Bot</h5>
                            <Stepper>
                                <Step>
                                    <StepNumber>1</StepNumber>
                                    <StepContent>
                                        <b>Add the Channel as an Admin:</b>
                                        <ul>
                                            <li>Open your Telegram channel and go to the Settings menu.</li>
                                            <li>Navigate to <b>Administrators</b> and add the Bot as an administrator of the channel.</li>
                                        </ul>

                                        <StepImage
                                            src='./img/finalChannel-step1.jpeg'
                                            className='d-flex col-12 col-md-6 '
                                        />
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>2</StepNumber>
                                    <StepContent>
                                        <b>Access Bot Settings:</b>
                                        <ul>
                                            <li>Open BotFather on Telegram and type <code>/mybots</code>.</li>
                                            <li>Select the bot you have created from the list of bots.</li>
                                        </ul>

                                        <StepImage
                                            src='./img/finalChannel-step2.jpeg'
                                            className='d-flex col-12 col-md-6 '
                                        />
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepNumber>3</StepNumber>
                                    <StepContent>
                                        <b>Update Bot Privacy Settings:</b>
                                        <ul>
                                            <li>Click on <b>Bot Settings</b>.</li>
                                            <li>Go to <b>Group Privacy</b>.</li>
                                            <li>Turn <b>Group Privacy</b> to <b>Off</b> to allow the bot to access messages in the channel or group.</li>
                                        </ul>
                                        <StepImage
                                            src='./img/finalChannel-step3.jpeg'
                                            className='d-flex col-12 col-md-6 '
                                        />
                                        <StepImage
                                            src='./img/finalChannel-step3-1.jpeg'
                                            className='d-flex col-12 col-md-6 '
                                        />
                                        <StepImage
                                            src='./img/finalChannel-step3-2.jpeg'
                                            className='d-flex col-12 col-md-6 '
                                        />
                                    </StepContent>
                                </Step>
                            </Stepper>
                        </InstructionSection>

                        <InstructionSection>
                            <h5>How to Get Telegram Username</h5>
                            <Stepper>
                                <Step>
                                    <StepNumber>1</StepNumber>
                                    <StepContent>
                                        <b>For Mobile Devices (Android and iOS):</b>
                                        <ul>
                                            <li>Open Telegram: Launch the Telegram app on your device.</li>
                                            <li>
                                                Access Settings:
                                                <ul>
                                                    <li><b>Android:</b> Tap the three horizontal lines (☰) in the top-left corner to open the menu, then select <b>Settings</b>.</li>
                                                    <li><b>iOS:</b> Tap the <b>Settings</b> icon located at the bottom-right corner.</li>
                                                </ul>
                                            </li>
                                            <li>View Username: In the <b>Settings</b> section, your username will be displayed.</li>
                                        </ul>
                                    </StepContent>
                                </Step>
                            </Stepper>
                        </InstructionSection>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    );
};

export default GuideInstructionModal;

// Styled Components
const InstructionSection = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.6;
  color: #333;

  h5 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #00897B;
  }
`;

const Stepper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 12px;
    width: 2px;
    height: 100%;
    background-color: #00897B;
  }
`;

const Step = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  position: relative;
`;

const StepNumber = styled.div`
  background-color: #00897B;
  color: white;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 14px;
  z-index: 1;
`;

const StepContent = styled.div`
  font-size: 14px;
  line-height: 1.6;
  color: #333;
`;
const StepImage = styled.img`
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
`;