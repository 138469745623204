/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import { Col, Container, Row } from 'react-bootstrap';
import Alert from '@/shared/components/Alert';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Coins from './components/Coins';
import Coins1 from './components/Coins1';
import WalletInfo from './components/WalletInfo';
import WalletInfo4 from './components/WalletInfo4';
import TopActivies from '../Finance/components/TopActivies';
import SendMetaMaskForm from '../SendPage/component/SendMetaMaskForm';
import ReceiveTable from '../Receive/component/ReceiveTable';
import { useHistory } from 'react-router';
import { logout } from '@/redux/actions/authActions';
import { getResultKYC, getUserInfo, startKYC } from '../../../utils/services/user.service';
import SubwalletCoins from './components/SubWalletCoins';
import StrigaWalletInfo from './components/StrigaWalletInfo';
import StrigaWalletCoins from './components/StrigaWalletCoins';
import { Ring } from '@uiball/loaders';
import Top5Coins from './components/Top5Coins';
import { useChainContext } from '../../context/context';

import Swap from '../Swap/Swap';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import OtcWalletCoins from './OtcWalletCoins';
import OtcWAlletInfo from './components/OtcWalletInfo';
import RecentContract from './components/RecentContract';

const Wallet = () => {
  const { t } = useTranslation('common');
  const { selectedDashboardCoin } = useChainContext()
  const rtl = useSelector(state => state.rtl);
  const isWallets = useSelector(state => state.wallets.wallets);
  const [isLoading, setIsLoading] = useState(false);
  const [userInfoData, setUserData] = useState(null);
  const [kycStatus, setStatus] = useState('');
  const [kycDetail, setKYCDetail] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();

  const checkTfaStatus = localStorage.getItem('tfaStatus')
  useEffect(() => {
    if (checkTfaStatus == "true") {
      history.push('/login');
      dispatch(logout());
    }
  }, [])
  const userData = async () => {
    try {
      const res = await getUserInfo()
      setUserData(res?.data?.User)

    } catch (error) {
      console.log("🚀 ~ userInfo ~ error:", error)
    }
  }

  useEffect(() => {
    userData()
  }, [selectedDashboardCoin]);


  const fetchGetResultKYC = async () => {
    try {

      const res = await getResultKYC()
      setStatus(res?.data?.verifications[0]?.status)
    } catch (error) {
      console.log("🚀 ~ createSubWalletUser ~ error:", error)
      // toast.error(error?.response?.data.Message);
    }
  }

  useEffect(() => {
    fetchGetResultKYC()
  }, [])

  const CreateResultKYC = async () => {
    try {
      setIsLoading(true)
      const res = await startKYC()
      setKYCDetail(res?.data?.verification?.url)
      if (res?.data?.verification?.url) {
        window.open(res.data.verification.url, "_blank");
        setIsLoading(false)
      }
    } catch (error) {
      console.log("🚀 ~ createSubWalletUser ~ error:", error)
      // toast.error(error?.response?.data.Message);
      setIsLoading(false)
    }
  }

  return (
    <Container>
      <Row>
        <div
          className="loader-overlay"
          style={{
            display: isLoading ? "flex" : "none", // Use flex display when loading
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 999,
          }}
        >
          <Ring size={65} color="#00897B" />
        </div>

        {
          (kycStatus == "" || kycStatus == "created" || kycStatus == "declined") &&
          <Alert color="warning" bordered icon style={{
            width: 'auto',
            display: 'flex',
            margin: 'auto'
          }}>
            <strong className='text-white'>Attention!</strong> <span className='text-white px-2'>Please click the button and verify your KYC</span>
            <strong onClick={CreateResultKYC} style={{
              cursor: "pointer",
              borderBottom: "1px solid #664D04",
              color: 'white'
            }}>Complete your KYC</strong>
          </Alert>
        }

        <Col md={12} className=''>
          <Top5Coins />
        </Col>
      </Row>
      {selectedDashboardCoin === 'internal' && (
        <Row className="mt-4 mb-0 overflow-hidden ">
          <WalletInfo dir={rtl.direction} />
          <Coins dir={rtl.direction} />
        </Row>
      )}
      {selectedDashboardCoin === 'onChain_wallet' && (
        <Row className="mt-4 mb-0 overflow-hidden ">
          <WalletInfo dir={rtl.direction} subWallet={userInfoData?.secondary_wallet_address || ""} />
          <SubwalletCoins dir={rtl.direction} />
        </Row>
      )}
      {selectedDashboardCoin === 'otc_wallet' && (
        <Row className="mt-4 mb-0 overflow-hidden ">
          <OtcWAlletInfo dir={rtl.direction} subWallet={userInfoData?.secondary_wallet_address || ""} />
          <OtcWalletCoins dir={rtl.direction} />
        </Row>
      )}
      {selectedDashboardCoin === 'bank_wallet' && (
        <Row className="mt-4 mb-0 overflow-hidden ">
          <StrigaWalletInfo dir={rtl.direction} userInfoData={userInfoData} subWallet={userInfoData?.striga_user_id || ""} />
          <StrigaWalletCoins dir={rtl.direction} />
        </Row>
      )}
      {selectedDashboardCoin === 'external' && (
        <Row className="mt-4 mb-0 overflow-hidden ">
          <WalletInfo4 dir={rtl.direction} />
          {isWallets && (
            <Coins1 dir={rtl.direction} />
          )}
        </Row>
      )}

      <Row className="mt-4 mb-0 overflow-hidden ">
        <RecentContract />
      </Row>
      <Row className="mt-4 mb-0 overflow-hidden ">
        <Swap />
      </Row>
      <Row className="mt-4 mb-0 overflow-hidden ">
        <SendMetaMaskForm />
      </Row>
      <Row className="mt-4 mb-0">
        <ReceiveTable />
      </Row>
      <Row className="mt-4 mb-0">
        <TopActivies />
      </Row>
      <Row className="mt-4 mb-0">
        <Col xs={12} md={4} lg={4} />
      </Row>
    </Container >
  );
};

export default Wallet;
