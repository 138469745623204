/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Panel from '@/shared/components/Panel';
import { Table } from '@/shared/components/TableElements';
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
// import Button from 'react-bootstrap/Button';
import { Button } from "../../../../shared/components/Button";

import Tooltip from '@/shared/components/Tooltip';
import ReactTableBase from '../../../../shared/components/table/ReactTableBase';
import { SidebarLinkIcon } from '../../../Layout/components/sidebar/SidebarLink';
import { CustomSelect, CustomMultiSelect } from '../../../../shared/components/CustomSelect';
import { getAllTokens, getDepositList, getStrigaWalletAddress } from '../../../../utils/services/user.service';
import { Ring } from '@uiball/loaders';
import config from "../../../../config/walletConnect";
import { chainIdToName } from '../../../../utils/constants/contants';


const user = JSON.parse(localStorage.getItem('user'));

const header = [
  { accessor: 'id', Header: 'QR Code', disableGlobalFilter: true },
  { accessor: 'address', Header: 'Address' },
  { accessor: 'network', Header: 'Network' },
  // { accessor: 'copy', Header: '' , disableGlobalFilter: true},
  { accessor: 'type', Header: 'Symbol' },
  // { accessor: 'code', Header: 'Code' },

];

const ReceiveTable = () => {
  const {
    REACT_APP_SMART_CHAIN,
    REACT_APP_POLYGON_MATIC,
    REACT_APP_BTC,
    REACT_APP_SOLANA,
    REACT_APP_ETHER,
    REACT_APP_TRON
  } = config;
  const optionSymbol1 = [
    { value: REACT_APP_ETHER, label: 'Ethereum' },
    { value: REACT_APP_BTC, label: 'Bitcoin' },
    { value: REACT_APP_POLYGON_MATIC, label: 'Polygon' },
    { value: REACT_APP_SMART_CHAIN, label: 'Smart Chain' },
    { value: REACT_APP_SOLANA, label: 'Solana' },
    { value: 'Bank Deposit', label: 'Bank Deposit' },
    { value: REACT_APP_TRON, label: 'Tron Network' },
  ];
  const [allTokens, setAllTokens] = useState([]);
  const fetchAllTokens = async () => {
    const payload = {
      chain_id: "",
      limit: 100,
      page_number: 1
    };
    const res = await getAllTokens(payload);
    setAllTokens(res.data.user);
  };
  useEffect(() => {
    fetchAllTokens()

  }, [])
  const optionSymbol12 = allTokens
    .filter((token) => token.chain_id === 321)
    .map((token) => ({
      value: token.symbol,
      label: token.symbol,
    }))
  const optionSymbolTron = [
    { value: 'ALL', label: 'ALL' },
    { value: 'TRON', label: 'TRON' },
    { value: 'USDT (TRC 20)', label: 'USDT (TRC 20)' },
  ];
  const optionSymbol = [
    { value: 'ALL', label: 'ALL' },
    { value: 'USDC', label: 'USDC' },
    { value: 'USDT', label: 'USDT' },
    { value: 'PAX', label: 'PAX' },
    { value: 'BNB', label: 'BNB' },
    { value: 'CTY', label: 'CTY' },
    { value: 'GBPT', label: 'GBPT' },
    { value: 'JPYC', label: 'JPYC' },
    { value: 'EUROC', label: 'EUROC' },
    { value: 'BUSD', label: 'BUSD' },
    { value: 'OPY', label: 'OPY' },
    { value: 'FTY', label: 'FTY' },
    { value: 'MET$', label: 'MET$' },
  ];
  const [colorCopy, setColorCopy] = React.useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState('all');
  const [selectedSymbol1, setSelectedSymbol1] = useState(REACT_APP_SMART_CHAIN);
  const [getSelectedCoins, setSelectedCoins] = useState(optionSymbol);
  const [strigaWAlletAddress, setStrigaWalletAddress] = useState(null)
  const [optionSymbol11, setOptionSymbol11] = useState([])
  const [selectedName, setSelectedName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedCoins, setFetchedCoins] = useState([]);
  const [dropdownCoins, setDropdownCoins] = useState([]);
  const [coinsData, setCoinsData] = useState([]);
  const [userAddress, setUserAddress] = useState(" ");

  const getStrigaWallet = async () => {
    // setIsLoading(true)
    const payload = {
      "striga_user_id": user.data.user.striga_user_id
    }
    const res = await getStrigaWalletAddress(payload)
    setStrigaWalletAddress(res?.data)
    // setIsLoading(false)
  }
  useEffect(() => {
    getStrigaWallet()
  }, [selectedSymbol, selectedSymbol1])
  const rows = [
    {
      id: 28,
      walletAddress: (strigaWAlletAddress?.IBAN || ''),
      network: 'Bank Deposit',
      type: 'EUR',
      code: 'BEP20',
    },
    {
      id: 29,
      walletAddress: strigaWAlletAddress?.blockchainDepositAddress || '',
      network: 'Bank Deposit',
      type: 'USDT',
      code: 'BEP20',
    },
    {
      id: 30,
      walletAddress: strigaWAlletAddress?.blockchainDepositAddress || '',
      network: 'Bank Deposit',
      type: 'USDC',
      code: 'BEP20',
    },
    {
      id: 31,
      walletAddress: strigaWAlletAddress?.blockchainDepositAddress || '',
      network: 'Bank Deposit',
      type: 'BNB',
      code: 'BEP20',
    },
    {
      id: 32,
      walletAddress: strigaWAlletAddress?.blockchainDepositAddress || '',
      network: 'Bank Deposit',
      type: 'ETH',
      code: 'BEP20',
    },
    {
      id: 33,
      walletAddress: strigaWAlletAddress?.blockchainDepositAddress || '',
      network: 'Bank Deposit',
      type: 'BTC',
      code: 'BEP20',
    }

  ];

  const [getTransactions, setTransactions] = useState(rows);

  const [getTransactions1, setTransactions1] = useState(rows);
  const [tooltipText, setTooltipText] = useState('Copy Address');

  const handleCopyClick = (value) => {
    console.log("🚀 ~ handleCopyClick ~ value:", value)
    // Copy the address
    onCopyWalletAddress(value);

    // Change tooltip text to "Copied!"
    setTooltipText('Copied!');

    // Set timeout to revert the tooltip text back to "Copy Address" after 2 seconds
    setTimeout(() => {
      setTooltipText('Copy Address');
    }, 2000);
  };


  const optionSymbolStriga = [
    { value: 'All', label: 'All' },
    { value: 'EUR', label: 'EUR' },
    { value: 'USDC', label: 'USDC' },
    { value: 'BTC', label: 'BTC' },
    { value: 'BNB', label: 'BNB' },
    { value: 'ETH', label: 'ETH' },
    { value: 'USDT', label: 'USDT' },
  ];

  const handleSymbolChange = (value) => {
    // console.log(value.value);
    let completedTransactions;
    if (value.value === 'ALL') {
      completedTransactions = rows;
    } else {
      completedTransactions = rows.filter(transaction => transaction.type === value.value);
    }
    if (selectedName !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.address.toLowerCase().includes(selectedName.toLowerCase()));
    }
    if (selectedSymbol1 !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.network === selectedSymbol1);
    }
    setTransactions(completedTransactions);
    setSelectedSymbol(value.value);
  }

  const handleSymbolChange1 = (value) => {

    let completedTransactions;
    completedTransactions = rows.filter(transaction => transaction.network === value.value);

    if (value.value === 'Bank Deposit') {
      setCoinsData(optionSymbolStriga);
      setSelectedSymbol('All');
      const validValues = ["All", 'USDC', 'USDT', 'BNB', "ETH", "EUR", "BTC"];
      completedTransactions = completedTransactions.filter(transaction => validValues.includes(transaction.type));
    }
    setTransactions(completedTransactions);
    setSelectedSymbol1(value.value);
  }

  const handleNameChange = (value) => {
    const partialValue = value.target.value;
    let completedTransactions;
    completedTransactions = getTransactions1.filter(transaction => transaction.address.toLowerCase().includes(partialValue.toLowerCase()));
    if (selectedSymbol !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.type === selectedSymbol);
    }
    if (selectedSymbol1 !== '') {
      completedTransactions = completedTransactions.filter(transaction => transaction.network === selectedSymbol1);
    }
    setTransactions(completedTransactions);
    setSelectedName(value.target.value);
  }

  const resetData = (order) => {
    setSelectedSymbol('BTC');
    setSelectedSymbol1('Bitcoin');
    setSelectedCoins(optionSymbol12);
    setSelectedName('');
    setTransactions(filteredData);
  };
  const data = [];
  const onCopyWalletAddress = (item) => {
    navigator.clipboard.writeText(item);
  };


  if (selectedSymbol1 === "Bank Deposit") {
    getTransactions?.map((item, index) => {
      data.push({
        id: (
          <div className='d-flex w-100 bg-white p-2 justify-content-center  align-items-center  py-3'>
            <QRCode size="90" level="H" value={item?.walletAddress} renderAs="canvas" />
          </div>
        ),
        address: (
          <>
            <span>{item?.walletAddress} </span>
            <Tooltip text={tooltipText} placement="top" style={{ cursor: 'pointer' }} dir="ltr">
              <SidebarLinkIcon className="lnr lnr-book" onClick={()=>handleCopyClick(item?.walletAddress)} />
            </Tooltip>
          </>
        ),
        network: item.network,
        // code: item.code,
        type: item.type
      });


    });
  } else {
    fetchedCoins?.map((item, index) => {
      data.push({
        id: (
          <div className='d-flex w-100 bg-white p-2 justify-content-center  align-items-center  py-3'>
            <QRCode size="90" level="H" value={userAddress} renderAs="canvas" />
          </div>
        ),
        address: (
          <>
            <span>{userAddress} </span>
            <Tooltip text={tooltipText} placement="top" style={{ cursor: 'pointer' }} dir="ltr">
              <SidebarLinkIcon className="lnr lnr-book" onClick={()=>handleCopyClick(userAddress)} />
            </Tooltip>
          </>
        ),
        network: chainIdToName[item.chain_id] || item.chain_id,
        // code: item.code,
        type: item.symbol
      });


    });
  }

  const tableConfig = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [5, 10, 15, 20],
    placeholder: 'Search...',
    isPagination: true,
  };
  useEffect(() => {
    // Mapping the response data to create the desired structure
    const newArray = [
      { label: "All", value: "all" },  // First object
      ...dropdownCoins?.map((data) => ({
        label: data.symbol,
        value: data.symbol,
      })),
    ];
    setCoinsData(newArray);

  }, [dropdownCoins]);
  const fetchAllDepositList = async () => {
    setIsLoading(true)
    try {
      const payload = { chain_id: selectedSymbol1, symbol: selectedSymbol }
      const res = await getDepositList(payload)

      setFetchedCoins(res?.data?.data)
      setDropdownCoins(res?.data?.symbol)
      setUserAddress(res?.data?.wallet_address)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (selectedSymbol1 !== "Bank Deposit") {
      fetchAllDepositList()
    }
  }, [selectedSymbol1, selectedSymbol])
  return (
    <Panel lg={12} title="Deposit" closeBtn minusBtn refreshLoad>

      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Row className="mb-9">
        <Col xs={12} md={4} lg={3}>
          <InputDiv>
            <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mt-2 mb-0">Address</label>
            <ChatSearchInput
              type="text"
              value={selectedName}
              className="form-control mt-2"
              placeholder="Search by Address"
              onChange={handleNameChange}
              id="File2"
              name="File2"
            />
          </InputDiv>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-0 mt-2">Network</label>
          <InputDiv className="px-0">
            <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
              <CustomSelect
                name="size"
                id="size"
                className="w-25 px-0"
                value={selectedSymbol1}
                onChange={(value) => {
                  if (value.value == 'Bank Deposit') {
                    console.log('inside')
                    handleSymbolChange1(value)
                  } else {

                    setSelectedSymbol1(value.value)
                    setSelectedSymbol('all')
                  }
                }}
                options={optionSymbol1}
              />
            </DropdownDiv>
          </InputDiv>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-0 mt-2">Symbol</label>
          <InputDiv className="px-0">
            <DropdownDiv className="mb-0" style={{ textTransform: 'Capitalize' }}>
              <CustomSelect
                name="size"
                id="size"
                className="w-25 px-0"
                value={selectedSymbol}
                // onChange={handleSymbolChange}
                onChange={(value) => {
                  if (selectedSymbol1 == "Bank Deposit") {
                    handleSymbolChange(value)
                  } else
                    setSelectedSymbol(value.value)
                }}

                options={coinsData}
              />
            </DropdownDiv>
          </InputDiv>
        </Col>
        <Col xs={12} md={4} lg={3}>
          {/* <label style={{ fontSize: 'small' }} htmlFor="features" className="form-label text-white mb-0 mt-2">Type</label> */}
          <InputDiv className="px-0">
            <FormButton
              type="submit"
              variant="danger-outline"
              className="btn-block mt-4"
              onClick={() => resetData(1)}

            >
              Reset  <i className="fa fa-refresh" />
            </FormButton>
          </InputDiv>
        </Col>

      </Row>
      <ReactTableBase
        columns={header}
        data={data}
        tableConfig={tableConfig}
      />
    </Panel>
  );
};

export default ReceiveTable;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;  
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus, &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
