import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { fetchAllCurrencies1 } from "@/redux/actions/FetchCoinLists";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import { Ring } from "@uiball/loaders";
import numeral from "numeral";
import { Button } from "@/shared/components/Button";
import { exchangeRates, getAllWallets, getSwapOverview, getUser, getUserInfo, swap, swapCurrencies } from "../../../utils/services/user.service";
import { toast } from "react-toastify";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import StrigaWalletDetailModal from "./StrigaWalletDetailModal";
import { useChainContext } from "../../context/context";
import Panel from '../../../shared/components/Panel'

const Swap = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { coinList } = useSelector((state) => ({
    coinList: state.coinList1.data,
  }));
  const [payCoins, setPayCoins] = useState([]);
  const [recieveCoins, setRecieveCoins] = useState([]);
  const [payAmount, setPayAmount] = useState("");
  const [selectedPayCoin, setSelectedPayCoin] = useState(null);
  const [selectedPayCoinLabel, setSelectedPayCoinLabel] = useState(null);
  const [selectedRecieveCoin, setSelectedRecieveCoin] = useState(null);
  const [selectedRecieveCoinLabel, setSelectedRecieveCoinLabel] = useState(null);
  const [payBalance, setPayBalance] = useState(null);
  const [RecieveBalance, setRecieveBalance] = useState(null);
  const [swapOverviewData, setSwapOverviewData] = useState(null);
  const [showWallet, setShowWallet] = useState(false);
  const [userData, setUserData] = useState(null)
  const [strigaUserData, setStrigaUserData] = useState(null)
  const [reFetch, setRefetch] = useState(false)
  const [strigaWalletDetail, setStrigaWalletDetail] = useState(null)
  const [StrigaWalletModalData, setStrigaWalletModalData] = useState(null)

  const [payAmountStriga, setPayAmountStriga] = useState("");
  const [selectedPayCoinStriga, setSelectedPayCoinStriga] = useState(null);
  const [selectedRecieveCoinStriga, setSelectedRecieveCoinStriga] = useState(null);
  const [swapOverviewDataStriga, setSwapOverviewDataStriga] = useState(null);
  const [From_array, setArray1] = useState([]);
  const [To_array, setArray2] = useState([]);
  const { setRefreshInternalBalance, refreshInternalBalace } = useChainContext()
  const SmallLabel = ({ text, smallText }) => (
    <span>
      {text}{" "}
      <small>
        <b>{smallText}</b>
      </small>
    </span>
  );
  useEffect(() => {
    const fromSymbols = {};
    const toSymbols = {};

    const extractedArray1 = [];
    const extractedArray2 = [];

    if (strigaWalletDetail?.swapValidations && strigaWalletDetail?.wallets?.length) {
      strigaWalletDetail.swapValidations.forEach(item => {
        strigaWalletDetail.wallets.forEach(wallet => {
          // Ensure wallet.accounts is an array
          const accounts = Object.values(wallet.accounts);

          accounts.forEach(account => {
            // Process from_symbol
            if (!fromSymbols[item.from_symbol] && account.currency === item.from_symbol) {
              fromSymbols[item.from_symbol] = true;
              extractedArray1.push({
                from_symbol: item.from_symbol,
                // label: item.from_symbol,
                label: <SmallLabel text={item.from_symbol} smallText={account?.availableBalance?.hAmount} />,

                value: item.from_symbol,
                min_amount: item.min_amount,
                balance: account?.availableBalance?.hAmount,
                accountId: account?.accountId
              });
            }

            // Process to_symbol
            if (!toSymbols[item.to_symbol] && account.currency === item.to_symbol) {
              toSymbols[item.to_symbol] = true;
              extractedArray2.push({
                from_symbol: item.from_symbol,
                // label: item.to_symbol,
                label: <SmallLabel text={item.to_symbol} smallText={account?.availableBalance?.hAmount} />,

                value: item.to_symbol,
                min_amount: item.min_amount,
                accountId: account?.accountId
              });
            }
          });
        });
      });
    }

    setArray1(extractedArray1);
    setArray2(extractedArray2);
    if (selectedPayCoinStriga) {
      const modifiedToArray = extractedArray2.filter(item => item.from_symbol === selectedPayCoinStriga.label);
      setArray2(modifiedToArray);
    }
  }, [strigaWalletDetail, selectedPayCoinStriga]);


  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchAllCurrencies1());
    setIsLoading(false);
  }, [dispatch]);

  useEffect(() => {
    const payCoinsOptions = coinList.map((item) => ({
      label: item.symbol,
      value: item.symbol,
      balance: item?.balance,
    }));
    setPayCoins(payCoinsOptions);
    setRecieveCoins(payCoinsOptions);
  }, [coinList]);

  useEffect(() => {
    const updatedPayCoins = payCoins.filter(
      (coin) => coin.label !== selectedRecieveCoinLabel
    );
    setPayCoins(updatedPayCoins);
  }, [selectedRecieveCoinLabel]);

  useEffect(() => {
    const updatedRecieveCoins = recieveCoins.filter(
      (coin) => coin.label !== selectedPayCoinLabel
    );
    setRecieveCoins(updatedRecieveCoins);
  }, [selectedPayCoinLabel]);

  const swapTokens = async () => {
    setIsLoading(true);
    try {
      const payload = {
        from_symbol: selectedPayCoin,
        to_symbol: selectedRecieveCoin,
        amount: payAmount,
      };
      const res = await swap(payload);
      setIsLoading(false);
    } catch (error) {
      console.log(
        "🚀 ~ fetchSwapOverView ~ error:",
        error?.response?.data.Message
      );
      toast.error(error?.response?.data.Message);
      setIsLoading(false);
    }
  };
  const fetchSwapOverView = async () => {
    if (payAmount > payBalance) {
      // Show toast error if user enters amount more than available balance
      toast.error("Cannot enter amount more than available balance.");
      return;
    }
    setIsLoading(true);
    try {
      const payload = {
        from_symbol: selectedPayCoin,
        to_symbol: selectedRecieveCoin,
        amount: payAmount,
      };
      const res = await getSwapOverview(payload);

      setSwapOverviewData(res?.data?.result);
      setIsLoading(false);
    } catch (error) {
      console.log("🚀 ~ fetchSwapOverView ~ error:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      selectedRecieveCoin != null &&
      selectedPayCoin != null &&
      payAmount != null
    ) {
      fetchSwapOverView();
    }
  }, [selectedRecieveCoin]);

  const handleYoyPayChange = (e) => {
    const inputValue = parseFloat(e.target.value);
    if (inputValue > payBalance && selectedPayCoin) {
      toast.error("Cannot enter amount more than available balance.");
      return;
    } else {
      setPayAmount(inputValue);
    }
  };


  const fetchUserInfo = async () => {
    setIsLoading(true)
    try {
      const res = await getUserInfo()
      setUserData(res?.data?.User)
      setIsLoading(false)
    } catch (error) {
      console.log("🚀 ~ fetchUserInfo ~ error:", error)
      setIsLoading(false)

    }
  }
  const fetchStrigaUser = async () => {
    setIsLoading(true)
    try {
      const res = await getUser()
      setStrigaUserData(res?.data)
      setIsLoading(false)
    } catch (error) {
      console.log("🚀 ~ fetchUserInfo ~ error:", error)
      setIsLoading(false)

    }
  }

  useEffect(() => {
    fetchUserInfo();
    fetchStrigaUser();
    getWalletDetail()
  }, [reFetch])

  const getWalletDetail = async () => {
    setIsLoading(true)
    try {
      const res = await getAllWallets()
      setStrigaWalletDetail(res?.data)
      setIsLoading(false)
    } catch (error) {
      console.log("🚀 ~ getWalletDetail ~ error:", error)
      setIsLoading(false)
    }
  }

  const fetchSwapOverViewStriga = async () => {
    if ((payAmountStriga > selectedPayCoinStriga?.balance) && (payAmountStriga > selectedPayCoinStriga?.min_amount)) {
      // Show toast error if user enters amount more than available balance
      toast.error("Cannot enter amount more than available balance.");
      return;
    }
    setIsLoading(true);
    try {
      const payload = {
        from_symbol: selectedPayCoinStriga?.value,
        to_symbol: selectedRecieveCoinStriga?.value,
        amount: payAmountStriga,
        accountId: selectedPayCoinStriga?.accountId
      };
      const res = await exchangeRates(payload);
      console.log("🚀 ~ fetchSwapOverViewStriga ~ res:", res)

      setSwapOverviewDataStriga(res?.data?.convertedAmount);
      setIsLoading(false);
    } catch (error) {
      console.log("🚀 ~ fetchSwapOverView ~ error:", error);
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (
      selectedRecieveCoinStriga?.value != null &&

      payAmountStriga != null
    ) {
      fetchSwapOverViewStriga();
    }
  }, [selectedRecieveCoinStriga, payAmountStriga]);


  const swapTokensStriga = async () => {
    setIsLoading(true);
    try {
      const payload = {
        from_symbol: selectedPayCoinStriga?.value,
        to_symbol: selectedRecieveCoinStriga?.value,
        sourceAccountId: selectedPayCoinStriga?.accountId,
        destinationAccountId: selectedRecieveCoinStriga?.accountId,
        amount: payAmountStriga
      };
      const res = await swapCurrencies(payload);
      getWalletDetail()
      if (res.data) {
        toast.success("Tokens Swap Successfully ")
        setRefreshInternalBalance(!refreshInternalBalace)
      }


      setIsLoading(false);
    } catch (error) {
      console.log(
        "🚀 ~ fetchSwapOverView ~ error:",
        error?.response?.data.Message
      );
      toast.error(error?.response?.data.Message);
      setIsLoading(false);
    }
  };
  return (
    <>
      <Panel lg={12} title="Swap"  refreshLoad={false}>
        <div
          className="loader-overlay"
          style={{
            display: isLoading ? "flex" : "none", // Use flex display when loading
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 999,
          }}
        >
          <Ring size={65} color="#00897B" />
        </div>
        {/* <h3 className="d-flex py-2 px-3">Swap</h3> */}

        <div className=" d-flex  flex-column overflow-hidden ">
          <div className="col-lg-12 col-md-12 col-sm-12 overflow-hidden ">
            <Tabs
              defaultActiveKey="Profile"
              transition={false}
              id="noanim-tab-example"
              className="my-4 overflow-hidden "
            >
              {/* <Tab eventKey="home" ClassName="text-white" title="Swap">
              <div class="d-flex flex-column w-100 w-md-75 align-self-center m-auto justify-content-center align-items-center">
                <div className=" d-flex flex-column w-100 gap-1 justify-content-center  align-items-center m-auto ">
                  <StyledDiv>
                    <div className="d-flex flex-column gap-2  ">
                      <p>You pay</p>

                      <div className="d-flex justify-content-between ">
                        <InputDiv>
                          <ChatSearchInput
                            type="text"
                            value={payAmount}
                            className="form-control mt-2"
                            placeholder="0"
                            onChange={handleYoyPayChange}
                          />
                          {swapOverviewData && (
                            <p
                              className="d-flex  "
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              {numeral(swapOverviewData.from_usdt_worth).format(
                                "0,0.00"
                              ) || 0}{" "}
                              $
                            </p>
                          )}
                        </InputDiv>

                        <InputDiv className="px-0">
                          <DropdownDiv
                            className="mb-0 z-3 "
                            style={{ textTransform: "Capitalize" }}
                          >
                            <CustomSelect
                              name="size"
                              id="size"
                              className="w-25 px-0"
                              value={selectedPayCoinLabel}
                              onChange={(value) => {
                                setSelectedPayCoin(value.value);
                                setSelectedPayCoinLabel(value.label);
                                setPayBalance(value.balance);
                              }}
                              options={payCoins}
                            />
                          </DropdownDiv>
                          <p className="d-flex align-self-end ">
                            Balance: {numeral(payBalance).format("0,0.00") || 0}
                          </p>
                        </InputDiv>
                      </div>
                    </div>
                  </StyledDiv>
                  <i
                    className="fa fa-long-arrow-down text-white position-absolute z-1 border p-2 px-3 rounded-3  "
                    aria-hidden="true"
                    style={{
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  ></i>

                  <StyledDiv>
                    <div className="d-flex flex-column gap-2  ">
                      <p>You recieve</p>

                      <div className="d-flex justify-content-between ">
                        <InputDiv>
                          <ChatSearchInput
                            type="text"
                            value={swapOverviewData?.to_price || 0}
                            className="form-control mt-2"
                            placeholder="0"
                          // onChange={(e) => setPayAmount(e.target.value)}
                          />

                          {swapOverviewData && (
                            <p
                              className="d-flex  "
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              {numeral(swapOverviewData?.to_usdt_worth).format(
                                "0,0.00"
                              ) || 0}{" "}
                              $
                            </p>
                          )}
                        </InputDiv>

                        <InputDiv className="px-0">
                          <DropdownDiv
                            className="mb-0 z-2 "
                            style={{ textTransform: "Capitalize" }}
                          >
                            <CustomSelect
                              name="size"
                              id="size"
                              className="w-25 px-0"
                              value={selectedRecieveCoinLabel}
                              onChange={(value) => {
                                setSelectedRecieveCoin(value.value);
                                setSelectedRecieveCoinLabel(value.label);
                                setRecieveBalance(value.balance);
                              }}
                              options={recieveCoins}
                            />
                          </DropdownDiv>
                          <p className="d-flex align-self-end ">
                            Balance:{" "}
                            {numeral(RecieveBalance).format("0,0.00") || 0}
                          </p>
                        </InputDiv>
                      </div>
                    </div>
                  </StyledDiv>
                </div>

                <div className="d-flex flex-column  gap-2 w-100 ">
                  {swapOverviewData && (
                    <div className="d-flex flex-column align-self-center  w-25 ">
                      <div className="d-flex gap-4 justify-content-between w-75   mt-2 m-auto px-2  text-white   ">
                        <p>{`1 ${selectedPayCoin}  `}</p>
                        <p>{`${swapOverviewData?.from_price} $`}</p>
                      </div>
                      <div className="d-flex gap-4 justify-content-between w-75  mt-2 m-auto px-2  text-white   ">
                        <p>{`1 ${selectedRecieveCoin}  `}</p>
                        <p>{`${swapOverviewData?.to_price} $`}</p>
                      </div>
                      <div className="d-flex gap-4 justify-content-between w-75 mt-2 m-auto px-2  text-white   ">
                        <p>Fee </p>
                        <p>{`${swapOverviewData?.fee} $`}</p>
                      </div>
                    </div>
                  )}

                  <FormButton
                    variant="outline-primary"
                    type="submit"
                    disabled={!payAmount || payAmount == 0 || !swapOverviewData}
                    onClick={swapTokens}
                  >
                    Swap
                  </FormButton>
                </div>
              </div>
            </Tab> */}
              <Tab eventKey="Profile" ClassName="text-white" title="Bank Wallet Swap">
                {
                  userData?.striga_user_id && (strigaUserData?.KYC?.emailVerified && strigaUserData?.KYC?.mobileVerified && strigaUserData?.KYC?.tier1?.status === "APPROVED") ?
                    <div className="d-flex flex-column w-100 w-md-75 align-self-center m-auto justify-content-center align-items-center">
                      <div className="d-flex flex-column flex-lg-row w-100 gap-1 justify-content-center align-items-center m-auto">
                        <StyledDiv>
                          <div className="d-flex flex-column gap-2  ">
                            <p>You pay</p>

                            <div className="d-flex justify-content-between ">
                              <InputDiv>
                                <ChatSearchInput
                                  type="number"
                                  value={payAmountStriga}
                                  className="form-control mt-2"
                                  placeholder="0"
                                  onChange={(e) => {
                                    const inputValue = parseFloat(e.target.value);
                                    if (!selectedPayCoinStriga) {
                                      toast.error('Please choose any symbol first')
                                      return false
                                    }
                                    if (((inputValue > selectedPayCoinStriga?.balance)) && selectedPayCoinStriga?.label) {
                                      toast.error("Cannot enter amount more than available balance.");
                                      return;
                                    } else {
                                      setPayAmountStriga(inputValue);
                                    }
                                  }}
                                />
                                {swapOverviewData && (
                                  <p
                                    className="d-flex  "
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {numeral(swapOverviewData.from_usdt_worth).format(
                                      "0,0.00"
                                    ) || 0}{" "}
                                    $
                                  </p>
                                )}
                              </InputDiv>

                              <InputDiv className="px-0">
                                <DropdownDiv
                                  className="mb-0 z-3 "
                                  style={{ textTransform: "Capitalize" }}
                                >
                                  <CustomSelect
                                    name="size"
                                    id="size"
                                    className="w-25 px-0"
                                    value={selectedPayCoinStriga?.label}
                                    onChange={(value) => {
                                      setSelectedPayCoinStriga(value);
                                    }}
                                    options={From_array}
                                  />
                                </DropdownDiv>
                                <p className="d-flex align-self-end ">
                                  Balance: {numeral(selectedPayCoinStriga?.balance).format("0,0.00") || 0}
                                </p>
                              </InputDiv>
                            </div>
                          </div>
                        </StyledDiv>
                        <i
                          className="d-none d-sm-block d-lg-none fa fa-long-arrow-down text-white position-absolute z-1 border p-2 px-3 rounded-3  "
                          aria-hidden="true"
                          style={{
                            fontSize: "18px",
                            cursor: "pointer",
                          }}
                        ></i>

                        <StyledDiv>
                          <div className="d-flex flex-column gap-2  ">
                            <p>You recieve</p>

                            <div className="d-flex justify-content-between ">
                              <InputDiv>
                                <ChatSearchInput
                                  type="text"
                                  value={swapOverviewDataStriga || 0}
                                  className="form-control mt-2"
                                  placeholder="0"
                                  onChange={(e) => {
                                    console.log(e)
                                  }}
                                />

                                {swapOverviewData && (
                                  <p
                                    className="d-flex  "
                                    style={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {numeral(swapOverviewData?.to_usdt_worth).format(
                                      "0,0.00"
                                    ) || 0}{" "}
                                    $
                                  </p>
                                )}
                              </InputDiv>

                              <InputDiv className="px-0">
                                <DropdownDiv
                                  className="mb-0 z-2 "
                                  style={{ textTransform: "Capitalize" }}
                                >
                                  <CustomSelect
                                    name="size"
                                    id="size"
                                    className="w-25 px-0"
                                    value={selectedRecieveCoinStriga?.label}
                                    onChange={(value) => {
                                      if (!payAmountStriga) {
                                        toast.error('Please enter pay amount first')
                                        return false
                                      }
                                      setSelectedRecieveCoinStriga(value);
                                    }}
                                    options={To_array}
                                  />
                                </DropdownDiv>
                                <p className="d-flex align-self-end ">
                                  Balance:{" "}
                                  {numeral(RecieveBalance).format("0,0.00") || 0}
                                </p>
                              </InputDiv>
                            </div>
                          </div>
                        </StyledDiv>
                      </div>

                      <div className="d-flex flex-column  gap-2 w-100 ">
                        {swapOverviewData && (
                          <div className="d-flex flex-column align-self-center  w-25 ">
                            <div className="d-flex gap-4 justify-content-between w-75   mt-2 m-auto px-2  text-white   ">
                              <p>{`1 ${selectedPayCoin}  `}</p>
                              <p>{`${swapOverviewData?.from_price} $`}</p>
                            </div>
                            <div className="d-flex gap-4 justify-content-between w-75  mt-2 m-auto px-2  text-white   ">
                              <p>{`1 ${selectedRecieveCoin}  `}</p>
                              <p>{`${swapOverviewData?.to_price} $`}</p>
                            </div>
                            <div className="d-flex gap-4 justify-content-between w-75 mt-2 m-auto px-2  text-white   ">
                              <p>Fee </p>
                              <p>{`${swapOverviewData?.fee} $`}</p>
                            </div>
                          </div>
                        )}

                        {swapOverviewDataStriga && swapOverviewDataStriga < selectedRecieveCoinStriga.min_amount && <p className="d-flex text-danger align-self-center">Recieve Amount should be greater than {selectedRecieveCoinStriga.min_amount}  </p>
                        }
                        <FormButton
                          variant="outline-primary"
                          className='mt-5'
                          type="submit"
                          disabled={!payAmountStriga || payAmountStriga == 0 || !swapOverviewDataStriga || swapOverviewDataStriga < selectedRecieveCoinStriga.min_amount}
                          onClick={swapTokensStriga}
                        >
                          Swap
                        </FormButton>
                      </div>
                    </div>
                    :
                    <p>You don't have Bank Account</p>
                }
              </Tab>
              {/* <Tab eventKey="buy" ClassName="text-white" title="Buy">
              <div class="d-flex flex-column w-100 w-md-75 align-self-center m-auto justify-content-center align-items-center">
                <p>Buy feature is Coming Soon...</p>
              </div>
            </Tab> */}

            </Tabs>
          </div>
        </div>
        {
          showWallet && <StrigaWalletDetailModal show={showWallet} hide={() => setShowWallet(false)} data={StrigaWalletModalData} />
        }
      </Panel>
    </>
  );
};
export default Swap;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  margin: auto;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    width: 75%;
  }
`;

const StyledDiv = styled.div`
  background-color: rgb(27, 27, 27);
  border-radius: 18px;
  color: rgb(155, 155, 155);
  font-size: 14px;
  font-weight: 500;
  height: 140px;
  line-height: 20px;
  padding: 16px;
  position: relative;
  width: 45%;
  @media only screen and (max-width: 768px) {
    width: 95%;
    padding: 12px;
  }
`;

const StyledDivInfo = styled.div`
  background-color: rgb(27, 27, 27);
  display:flex;
  flex-direction: column;
  gap:10px;
  border-radius: 18px;
  color: rgb(155, 155, 155);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 16px;
  position: relative;
  width: 100%;
  cursor:pointer;
  @media only screen and (max-width: 768px) {
    width: 95%;
    padding: 12px;
  }
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
  gap: 5px;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 18px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  margin-top: 1rem;
  width: 45%;
  align-self: center;

  @media screen and (max-width: 876px) {
    width: 95%;
    align-self: center;
  }
`;
