/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable radix */
import React, { useState, useEffect, useRef } from 'react';

import { useParams, Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Ring } from '@uiball/loaders';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import 'react-toastify/dist/ReactToastify.css';
import '../style/product.css';
import {
  addCertificate,
  addTrackingDescription,
  getApprover,
  getCurrentOwner,
  getProductInformation,
  getTrackingInnerInfo,
  transferOwnership,
} from '../../../utils/services/user.service';
import { useLocation } from 'react-router-dom';
import { Button, ButtonGroup } from '../../../shared/components/Button';
import OwnerStepper from './OwnerStepper';
import { CustomSelect } from '../../../shared/components/CustomSelect';
import { toast } from 'react-toastify';
import { Formik } from "formik";
import LocationSearch from './LocationSearch';
import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import CertificateTimeLineItem from './Certificatetimeline';
import { colorDustyWhite, colorText } from "@/utils/palette";
import { left } from "@/utils/directions";


const ProductTrackingDetails = () => {

  const { productId } = useParams(); // Extracts the product ID from the URL
  const [product, setProduct] = useState([]);
  console.log("🚀 ~ ProductTrackingDetails ~ product:", product[0])
  const [currentOwners, setCurrentOwners] = useState([]);
  console.log("🚀 ~ ProductTrackingDetails ~ currentOwners:", currentOwners)
  const [isLoading, setIsLoading] = useState(false);
  const [showOwnershipButton, setShowOwnershipButton] = useState(true)
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [currentOwnerId, setCurrenOwnerId] = useState("");
  const [activeTab, setActiveTab] = useState('profile'); // State for active tab
  const [coordinates, setCoordinates] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDetailForm, setShowDetailForm] = useState(false);
  const [showTransferForm, setShowTransferForm] = useState(false);
  const formRef = useRef(null);
  const [emailFilter, setEmailFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");

  // Filter function based on email and date
  const filteredHistory = product[0]?.history.filter((owner) => {
    // Filter by email
    const matchesEmail = emailFilter
      ? owner?.owner[0]?.email.toLowerCase().includes(emailFilter.toLowerCase())
      : true;

    // Filter by date
    const matchesDate = dateFilter
      ? new Date(owner.createdAt).toLocaleDateString() === new Date(dateFilter).toLocaleDateString()
      : true;

    return matchesEmail && matchesDate;
  });
  const myUserId = JSON.parse(localStorage.getItem("user")).data.user.user_id
  const [locationData, setLocationData] = useState({
    location: '',
    coordinates: { lat: 0, lng: 0 },
  });

  const handleCoordinatesChange = ({ location, coordinates }) => {
    setCoordinates(coordinates)
    setLocationData({ location, coordinates });
  };
  const fetchProductInformation = async () => {
    try {
      setIsLoading(true)
      const payload = {
        record_id: productId
      }
      const res = await getTrackingInnerInfo(payload)
      console.log("🚀 ~ fetchProductInformation ~ res:", res)
      setProduct(res?.data?.data)
      setCurrentOwners(res?.data?.data[0]?.current_owner)
      setCurrenOwnerId(res?.data?.data[0]?.current_owner[0]?.current_owner_id)
      setIsLoading(false)

    } catch (error) {
      console.log("🚀 ~ fetchProductInformation ~ error:", error)
      setIsLoading(false)

    }
  }

  useEffect(() => {
    fetchProductInformation()
  }, [productId])


  // if (!product) {
  //   return <div>Loading...</div>;
  // }
  const NoImg = `${process.env.PUBLIC_URL}/img/no-img.png`;

  const handleInputChange = (name) => {

    // setsearchLoad(true);

    if (name.length >= 2) {
      getApprover({ name })
        .then((res) => {
          // setsearchLoad(false);

          const arr = res.data.user.map((i) => ({
            id: i._id,
            address: i.address,
            value: i.name,
            label: i.name,
            // sol_address: i?.sol_address,
            // onChainAddress: i?.secondary_wallet_address,
            // onChainSolAddress: i?.secondary_wallet_sol_address,
            // trcWalletAddress: i?.trc_address,
            // striga_user_id: i?.striga_user_id
          }));

          // setsearchLoad(false);
          // Case-insensitive comparison
          const matchingOptions = arr.filter(
            (option) => option.value.toLowerCase() === name.toLowerCase()
          );
          setUsersList(matchingOptions)
          console.log("🚀 ~ .then ~ matchingOptions:", matchingOptions)

        })
        .catch((error) => {

        });
    } else {
      // setapproverData([]); // Clear options when input length is less than 2
    }
  };

  const transferOwnerFnc = async () => {
    console.log('inssss')
    setIsLoading(true)
    try {
      const payload = {
        tracking_info_id: product[0]?._id,
        new_owner_id: selectedUser?.id,
      };
      console.log("🚀 ~ transferOwnerFnc ~ payload:", payload)

      const res = await transferOwnership(payload)
      console.log("🚀 ~ transferOwnerFnc ~ res:", res)
      setIsLoading(false)
      fetchProductInformation()
      setActiveTab('profile');


    } catch (error) {
      setIsLoading(false)
      toast.error(error?.response?.data?.Message)

    }
  }
  const AddCertificationsData = async (values) => {
    setIsLoading(true)
    setShowForm(false)
    const formData = new FormData();

    // Add the product ID to FormData
    formData.append("record_id", productId);
    formData.append("certificate_name", values.title);
    formData.append("location", locationData?.location);
    formData.append("description", values.description);

    // Append files to FormData
    values.files.forEach((file, index) => {
      formData.append(`File`, file); // Add files with unique keys
    });

    try {
      const res = await addCertificate(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200) {
        setIsLoading(false)
        toast.success("Certification data added successfully!");
        fetchProductInformation()
      } else {
        toast.error("Failed to add certification data.");
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.error("Error uploading data:", error);
      toast.error("An error occurred while uploading data.");
    }
  };
  const AddDetailData = async (values) => {
    setIsLoading(true)
    setShowDetailForm(false)
    const formData = new FormData();

    // Add the product ID to FormData
    formData.append("record_id", productId);

    formData.append("location", locationData?.location);
    formData.append("description", values.description);

    // Append files to FormData
    values.files.forEach((file, index) => {
      formData.append(`File`, file); // Add files with unique keys
    });

    try {
      const res = await addTrackingDescription(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200) {
        setIsLoading(false)
        toast.success("Certification data added successfully!");
        fetchProductInformation()
      } else {
        toast.error("Failed to add certification data.");
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.error("Error uploading data:", error);
      toast.error("An error occurred while uploading data.");
    }
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? 'flex' : 'none', // Use flex display when loading
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Container className="mt-4 containerDiv">
        {product.length > 0 ? <div className="row">
          <div className="col-lg-4 hero-img py-1 justify-content-center">
            <div className="square-img-wrapper">
              <img src={product[0]?.images[0] || NoImg} className="card-img-top square-img" alt={`Imagee`} />
            </div>
          </div>
          <div className="col-lg-8 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center py-1 px-5">
            <h2 id="h2" style={{ textTransform: 'Capitalize' }}>{product[0]?.title}</h2>
            <div className="row px-2 py-4">
              <div data-aos="fade-up" data-aos-delay="300" className="col-12 text-center CreatorDiv1">
                <Row style={{ textTransform: 'Capitalize' }}>
                  <Col>
                    <p className="py-1 mb-0"> <b> General Description</b></p>
                    <p className="py-0 mb-2">{product[0]?.description}</p>
                  </Col>

                </Row>
              </div>
            </div>
            <hr className="mt-2 mx-0" style={{ border: '1px solid #b3b3b3', opacity: '1.1' }} />
          </div>
        </div> : <h5>No Data Found!</h5>}


        <Tabs
          // defaultActiveKey="profile"
          activeKey={activeTab} // Set active tab
          onSelect={(k) => setActiveTab(k)} // Handle tab change
          id="uncontrolled-tab-example"
          className="mb-3 mt-3"
        >

          <Tab eventKey="profile" title=" Tracking Details">

            <div className='d-flex flex-column flex-md-row gap-2'>
              {!showDetailForm && currentOwnerId === myUserId && (
                <div className="text-center my-4">
                  <Button
                    variant={showDetailForm ? "danger" : "outline-primary"}
                    onClick={() => {
                      setShowDetailForm(true);
                      setTimeout(() => {
                        formRef.current?.scrollIntoView({ behavior: "smooth" });
                      }, 0);
                    }}
                  >
                    Add New Detail
                  </Button>
                </div>
              )}
              {!showForm && currentOwnerId === myUserId && <div className="text-center my-4">
                <Button
                  variant={showForm ? "danger" : "outline-primary"}

                  onClick={() => {
                    setShowForm(true);
                    setTimeout(() => {
                      formRef.current?.scrollIntoView({ behavior: "smooth" });
                    }, 0);
                  }}
                >
                  {"Add New Certificate"}
                </Button>
              </div>}
              {!showTransferForm && currentOwnerId === myUserId && <div className="text-center my-4">
                <Button
                  variant={showForm ? "danger" : "outline-primary"}

                  onClick={() => {
                    setShowTransferForm(true);
                    setTimeout(() => {
                      formRef.current?.scrollIntoView({ behavior: "smooth" });
                    }, 0);
                  }}
                >
                  Transfer Ownership
                </Button>
              </div>}
            </div>

            <div className='mb-2 row'>
              <div className="col-12 col-md-4">
                <InputDiv>
                  <label
                    htmlFor="description"
                    className="form-label text-white"
                    style={{ fontSize: "small" }}
                  >
                    Email
                  </label>
                  <ChatSearchInput
                    className='mt-4'
                    name="description"
                    id="description"
                    placeholder="Search by Email"
                    value={emailFilter}
                    onChange={(e) => setEmailFilter(e.target.value)} // Handle email filter change
                  />
                </InputDiv>
              </div>

              <div className="col-12 col-md-4">
                <InputDiv>
                  <label
                    htmlFor="date"
                    className="form-label text-white"
                    style={{ fontSize: "small" }}
                  >
                    Select Date
                  </label>
                  <input
                    type="date"
                    name="date"
                    id="date"
                    className="form-control"
                    style={{
                      backgroundColor: "transparent",
                      color: "white",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "0.5rem",
                    }}
                    value={dateFilter}
                    onChange={(e) => setDateFilter(e.target.value)} // Handle date filter change
                  />
                </InputDiv>
              </div>
              <div className="col-12 col-md-4">
                <InputDiv>
                  <label
                    htmlFor="description"
                    className="form-label text-white"
                    style={{ fontSize: "small" }}
                  >
                    Reset
                  </label>
                  <Button
                    variant={"outline-danger"}

                    onClick={() => {
                      setDateFilter("");
                      setEmailFilter("")
                    }}
                  >
                    Reset
                  </Button>
                </InputDiv>
              </div>
            </div>

            <TimeLineWrap>
              {filteredHistory?.map((owner) => (
                <CertificateTimeLineItem
                  key={owner._id}
                  hash={owner.hash}
                  title={owner?.certificate_name || ""}
                  date={new Date(owner.createdAt).toLocaleDateString()}
                  description={owner?.description}
                  documentLink={owner?.documents}
                  type={owner?.type}
                  origin={owner.origin}
                  img={owner?.owner[0]?.image}
                  email={owner?.owner[0]?.email}
                />
              ))}
            </TimeLineWrap>


            {showDetailForm && (
              <div ref={formRef}>
                <Formik
                  initialValues={{
                    title: "",
                    description: "",
                    model: "",
                    Manufacturer: "",
                    Company: "",
                    store_link: "",
                    files: [], // Updated to handle multiple files
                  }}
                  onSubmit={(values, { resetForm }) => {
                    console.log("🚀 ~ Form Submitted:", values);
                    AddDetailData(values); // Function to handle form submission
                    resetForm(); // Reset form after submission
                  }}
                >
                  {(props) => {
                    const {
                      values, touched, errors, handleBlur, handleChange, handleSubmit, setFieldValue,
                    } = props;

                    const handleFileUpload = (event) => {
                      const files = Array.from(event.target.files);
                      const validFiles = files.filter((file) => [
                        "application/pdf",
                        "application/msword",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      ].includes(file.type)
                      );

                      if (values.files.length + validFiles.length > 20) {
                        toast.error("You can upload a maximum of 20 documents.");
                        return;
                      }

                      setFieldValue("files", [...values.files, ...validFiles]);
                    };

                    const removeFile = (index) => {
                      const updatedFiles = values.files.filter((_, i) => i !== index);
                      setFieldValue("files", updatedFiles);
                    };

                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="row">



                          {/* Description Field */}
                          <div className="col-12 col-md-4">
                            <InputDiv>
                              <label
                                htmlFor="description"
                                className="form-label text-white"
                                style={{ fontSize: "small" }}
                              >
                                Description
                              </label>
                              <ChatSearchInput
                                value={values.description}
                                name="description"
                                id="description"
                                placeholder="Enter Description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                            </InputDiv>
                          </div>

                          {/* File Upload Section */}
                          <div className="col-12 col-md-4">
                            <InputDiv>
                              <label
                                htmlFor="files"
                                className="form-label text-white"
                                style={{ fontSize: "small" }}
                              >
                                Upload Documents
                              </label>
                              <input
                                type="file"
                                id="files"
                                accept=".pdf,.doc,.docx,.xlsx"
                                multiple
                                style={{ display: "none" }}
                                onChange={handleFileUpload} />
                              <Button
                                type="button"
                                size="md"
                                className="py-2 btn-block"
                                variant="outline-info"
                                onClick={() => document.getElementById("files").click()}
                              >
                                Choose Documents
                              </Button>
                            </InputDiv>
                          </div>

                        </div>
                        {/* Display Uploaded Files */}
                        {values?.files.length > 0 && (
                          <div className="">
                            <div className="row">
                              {values.files.map((file, index) => (
                                <div
                                  key={index}
                                  className="col-lg-4 col-md-6 col-12 d-flex align-items-center"
                                >
                                  <div
                                    className="p-3 text-white rounded d-flex flex-column align-items-start"
                                    style={{ minHeight: "120px" }}
                                  >
                                    <span className="mb-2">{file.name}</span>
                                    <Button
                                      variant="outline-danger"
                                      size="sm"
                                      onClick={() => removeFile(index)}
                                    >
                                      Remove
                                    </Button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}


                        <LocationSearch onCoordinatesChange={handleCoordinatesChange} />


                        {/* Submit Button */}
                        <div className="row justify-content-center py-4">
                          <div className="col-12 col-md-3 text-center">
                            <Button
                              type="submit"
                              size="md"
                              className="py-2 btn-block"
                              variant="outline-primary"
                            >
                              Add Data
                            </Button>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>

            )}
            {showForm && (
              <div ref={formRef}>
                <Formik
                  initialValues={{
                    title: "",
                    description: "",
                    model: "",
                    Manufacturer: "",
                    Company: "",
                    store_link: "",
                    files: [], // Updated to handle multiple files
                  }}
                  onSubmit={(values, { resetForm }) => {
                    console.log("🚀 ~ Form Submitted:", values);
                    AddCertificationsData(values); // Function to handle form submission
                    resetForm(); // Reset form after submission
                  }}
                >
                  {(props) => {
                    const {
                      values, touched, errors, handleBlur, handleChange, handleSubmit, setFieldValue,
                    } = props;

                    const handleFileUpload = (event) => {
                      const files = Array.from(event.target.files);
                      const validFiles = files.filter((file) => [
                        "application/pdf",
                        "application/msword",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      ].includes(file.type)
                      );

                      if (values.files.length + validFiles.length > 20) {
                        toast.error("You can upload a maximum of 20 documents.");
                        return;
                      }

                      setFieldValue("files", [...values.files, ...validFiles]);
                    };

                    const removeFile = (index) => {
                      const updatedFiles = values.files.filter((_, i) => i !== index);
                      setFieldValue("files", updatedFiles);
                    };

                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          {/* Title Field */}
                          <div className="col-12">
                            <InputDiv>
                              <label
                                htmlFor="title"
                                className="form-label text-white"
                                style={{ fontSize: "small" }}
                              >
                                Certificate Title
                              </label>
                              <ChatSearchInput
                                type="text"
                                value={values.title}
                                name="title"
                                id="title"
                                placeholder="Enter Title"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                            </InputDiv>
                          </div>


                          {/* Description Field */}
                          <div className="col-12">
                            <InputDiv>
                              <label
                                htmlFor="description"
                                className="form-label text-white"
                                style={{ fontSize: "small" }}
                              >
                                Description
                              </label>
                              <ChatSearchInput
                                value={values.description}
                                name="description"
                                id="description"
                                placeholder="Enter Description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                            </InputDiv>
                          </div>

                          {/* Display Uploaded Files */}
                          {values?.files.length > 0 && (
                            <div className="mt-3">
                              <div className="row">
                                {values.files.map((file, index) => (
                                  <div
                                    key={index}
                                    className="col-lg-4 col-md-6 col-12 mb-3 d-flex align-items-center"
                                  >
                                    <div
                                      className="p-3 text-white rounded d-flex flex-column align-items-start"
                                      style={{ minHeight: "120px" }}
                                    >
                                      <span className="mb-2">{file.name}</span>
                                      <Button
                                        variant="outline-danger"
                                        size="sm"
                                        onClick={() => removeFile(index)}
                                      >
                                        Remove
                                      </Button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}

                          {/* File Upload Section */}
                          <div className="col-12 col-md-3">
                            <InputDiv>
                              <label
                                htmlFor="files"
                                className="form-label text-white"
                                style={{ fontSize: "small" }}
                              >
                                Upload Documents
                              </label>
                              <input
                                type="file"
                                id="files"
                                accept=".pdf,.doc,.docx,.xlsx"
                                multiple
                                style={{ display: "none" }}
                                onChange={handleFileUpload} />
                              <Button
                                type="button"
                                size="md"
                                className="py-2 btn-block"
                                variant="outline-info"
                                onClick={() => document.getElementById("files").click()}
                              >
                                Choose Documents
                              </Button>
                            </InputDiv>
                          </div>
                        </div>


                        <LocationSearch onCoordinatesChange={handleCoordinatesChange} />


                        {/* Submit Button */}
                        <div className="row py-4 justify-content-center">
                          <div className="col-12 col-md-3 text-center">
                            <Button
                              type="submit"
                              size="md"
                              className="py-2 btn-block"
                              variant="outline-primary"
                            >
                              Add Data
                            </Button>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>

            )}
            {
              showTransferForm &&
                 <>
                 <div
                 ref={formRef}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <CustomSelect
                  // isLoading={searchLoad}
                  name="receiver"
                  onInputChange={handleInputChange}
                  onChange={(value) => {
                    setSelectedUser(value);
                  } }
                  placeholder={selectedUser.label || "Select User"}

                  // value={receiver1}
                  options={usersList}
                  isSearchable />{" "}

              </div><Button size="sm" disabled={!selectedUser} onClick={transferOwnerFnc} className="btn btn-sm mb-2 mt-2 px-2" variant="outline-primary">
                  Transfer Ownership
                </Button></>
            }
          </Tab>

          {/* {<Tab eventKey="transferownership" title="Transfer Ownership">
            {currentOwnerId === myUserId && <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <CustomSelect
                // isLoading={searchLoad}
                name="receiver"
                onInputChange={handleInputChange}
                onChange={(value) => {
                  setSelectedUser(value);
                }}
                placeholder={selectedUser.label || "Select User"}

                // value={receiver1}
                options={usersList}
                isSearchable />{" "}

            </div><Button size="sm" disabled={!selectedUser} onClick={transferOwnerFnc} className="btn btn-sm mb-2 mt-2 px-2" variant="outline-primary">
                Transfer Ownership
              </Button>
              
              </>}

            <TimeLineWrap>
              {currentOwners?.length > 0 &&
                currentOwners?.map((owner, index) => {
                  const nextOwner = currentOwners[index + 1];
                  const startTime = owner.createdAt;
                  const endTime = nextOwner?.createdAt || 'active'; // Next owner's createdAt or 'active'

                  return owner?.owner_info?.map(({ image, name, _id, email }) => (
                    <CertificateTimeLineItem
                      key={_id}
                      img={image}
                      title={name}
                      email={email}
                      status={owner.status} // Pass the status here
                      date={`Start: ${moment(startTime).format('ll')} ${endTime !== 'active' ? `| End: ${moment(endTime).format('ll')}` : ''
                        }`}
                    />
                  ));
                })}
            </TimeLineWrap>



          </Tab>} */}


          {/* <Tab eventKey="ownership" title="Ownership ">


                <TimeLineWrap>
                  {currentOwners?.length > 0 &&
                    currentOwners?.map((owner, index) => {
                      const nextOwner = currentOwners[index + 1];
                      const startTime = owner.createdAt;
                      const endTime = nextOwner?.createdAt || 'active'; // Next owner's createdAt or 'active'

                      return owner?.owner_info?.map(({ image, name, _id, email }) => (
                        <CertificateTimeLineItem
                          key={_id}
                          img={image}
                          title={name}
                          email={email}
                          status={owner.status} // Pass the status here
                          date={`Start: ${moment(startTime).format('ll')} ${endTime !== 'active' ? `| End: ${moment(endTime).format('ll')}` : ''
                            }`}
                        />
                      ));
                    })}
                </TimeLineWrap>




              </Tab> */}

        </Tabs>

      </Container>
      <div className='py-4'>
      </div>


    </>
  );
};

export default ProductTrackingDetails;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;
const TimeLineWrap = styled.div`
  position: relative;
  color: ${colorText};

  &:before {
    content: "";
    background-color: ${colorDustyWhite};
    width: 1px;
    height: 100%;
    position: absolute;
    ${left}: 50%;
  }

  p {
    margin-top: 5px;
  }

  @media screen and (max-width: 991px) {
    &:before {
      ${left}: 25px;
    }
  }
`;
