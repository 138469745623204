import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { colorAccent, colorBackground } from '@/utils/palette';

const OwnerStepper = ({ owners }) => {
  return (
    <Container>

      <TimelineWrapper>
        {owners?.map((owner, index) => (
          <TimelineItemWrap key={owner._id}>
            <TimeLineIcon>
              <img
                src={owner.owner_info[0]?.image}
                alt={owner.owner_info[0]?.name || "Unknown"}
              />
            </TimeLineIcon>
            <TimeLineItemContent>
              <TimeLineItemTitle>
                {owner.owner_info[0]?.name || "Unknown"}
              </TimeLineItemTitle>
              {/* <DetailField>
                <strong>Status:</strong> {owner.status || "Inactive"}
              </DetailField>
              <DetailField>
                <strong>Email:</strong> {owner.owner_info[0]?.email || "N/A"}
              </DetailField> */}
            </TimeLineItemContent>
            {index < owners.length - 1 && <StepLine />}
          </TimelineItemWrap>
        ))}
      </TimelineWrapper>
    </Container>
  );
};

export default OwnerStepper;

// region STYLES

const TimelineWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 20px;
`;

const TimelineItemWrap = styled.div`
  position: relative;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  gap: 20px;

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 40px;
    background-color: ${colorAccent};

    @media (max-width: 768px) {
      top: auto;
      bottom: -40px;
    }
  }
`;

const TimeLineIcon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${colorBackground};
  border: 2px solid ${colorAccent};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TimeLineItemContent = styled.div`
  flex: 1;
  background-color: ${colorBackground};
  border-radius: 5px;
  padding: 15px 20px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    right: -10px;
    top: 20px;
    border: 10px solid transparent;
    border-left: 10px solid ${colorBackground};
  }

  @media (max-width: 768px) {
    &:after {
      right: auto;
      left: -10px;
      border-left: none;
      border-right: 10px solid ${colorBackground};
    }
  }
`;

const TimeLineItemTitle = styled.h5`
  font-weight: bold;
  text-transform: capitalize;
  color: ${colorAccent};
`;

const DetailField = styled.p`
  margin: 5px 0;
  font-size: 14px;
  line-height: 1.4;
`;

const StepLine = styled.div`
  width: 2px;
  height: 100%;
  background-color: ${colorAccent};
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 768px) {
    width: 100%;
    height: 2px;
    top: auto;
    bottom: 0;
  }
`;

// endregion
