/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable radix */
import React, { useCallback, useEffect, useState } from "react";
import {
  validate,
  getAddressInfo,
  Network,
  AddressInfo,
} from "bitcoin-address-validation";
import { Field, Formik } from "formik";
import styled from "styled-components";
import axios from "axios";
import Web3 from "web3";
import { toast } from "react-toastify";
import { Ring } from "@uiball/loaders";
import { useAccount, useBalance } from "wagmi";
import { Alert } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import {
  Switch,
  Stack,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import { fetchAllCurrencies1 } from "@/redux/actions/FetchCoinLists";
import Panel from "@/shared/components/Panel";
import config from "@/config/walletConnect";
import { Button } from "../../../../shared/components/Button";
import { CustomSelect } from "../../../../shared/components/CustomSelect";
import authHeader from "../../../../utils/services/auth-header";
import {
  API_URL,
  getApprover,
  getBeneficiaryName,
  approveExternalWalletOtp,
  calculateAdminCommissions,
  externalBSCCoins,
  getIpfs,
  verifyTFAEmail,
  userBalanceInternalWalletSubWallet
} from "../../../../utils/services/user.service";
import { getTotalBalance } from "../../../../redux/actions/sidebarActions";
import SimpleModal from "../../../../shared/components/Modal/SimpleModal";
import Tfaaa from "../../../Account/MyAccount/Tfaaa";
import Abi from "./Abi/tokenAbi.json";
import ContractAbi from "./Abi/contractAbi.json";
import SignaturePad from "../../../../store/Layout/orders/SignaturePad";
import PdfSignature from "../../../../store/Layout/orders/PdfSignature";
import {
  colorAccent,
  colorAccentHover,
  colorLightAccent,
} from "../../../../utils/palette";
import { externalWalletAddresses } from "../../../../utils/constants/contants";
import numeral from "numeral";

const ContractForm = ({ benficiaryToggle, chain }) => {
  const user1 = JSON.parse(localStorage.getItem("user"));
  const isWallets = useSelector((state) => state.wallets.wallets);
  const account = useAccount(); // Get External wallet status
  const [isWallet, setisWallet] = useState(false);
  const [walletApi, setwalletApi] = useState("createContract");
  const [tokenBalance, setTokenBalance] = useState(null);
  const [walletCoin, setwalletCoin] = useState("");
  const [isError, setIsError] = useState(false);
  const [uSDCoin, setUSDCoin] = useState("");
  const [otp, setOtp] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setloading] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isLoading1, setisLoading1] = useState(false);
  const [searchLoad, setsearchLoad] = useState(false);
  const [statusCode, setstatusCode] = useState(400);
  const [walletOtp, setwalletOtp] = useState(false);
  const [walletIpfs, setwalletIpfs] = useState([]);
  const [submitData, setSubmitData] = useState(null);
  const [transactionHash, setTransactionHash] = useState(null);
  const [contractAddress, setContractAddress] = useState("");
  const [toggle, setToggle] = useState(false);
  const [options, setOptions] = useState(null);
  const [externalOptions, setExternalOptions] = useState(null);
  const [externalCoin, setExternalCoin] = useState(null);
  const [resendOtp, setResendOtp] = useState(false);
  const [count, setcount] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [IsBtc, setIsBtc] = useState(false);
  const [btcAddress, setBtcAddress] = useState("");
  const [validBtcAddress, setValidBtcAddress] = useState(null);
  const [name1, setName] = useState("");
  const [amount1, setAmount] = useState("");
  const [coin1, setCoin] = useState(
    benficiaryToggle === "external" ? externalCoin : "USDT"
  );
  const [convertAmount, setConvertAmount] = useState(uSDCoin);
  const [commissionToken, setCommissionToken] = useState("");
  const [address1, setAddress] = useState(
    account.address ? account.address : user1.data.user.address
  );
  const [from1, setFrom] = useState(
    `${user1.data.user.firstName} ${user1.data.user.lastName}`
  );
  const [benficeryName, setBenficeryName] = useState("");
  const [benficeryAddress, setBenficeryAddress] = useState("");
  const [benficerDocsyName, setBenficeryDocsName] = useState("");
  const [benficeryDocsAddress, setBenficeryDocsAddress] = useState("");
  const [approverName, setApproverName] = useState("");
  const [approverAddress, setApproverAddress] = useState("");
  const [date1, setDate] = useState("");
  const [otp1, setOtp1] = useState("");
  // const [files, setFiles] = useState(null);
  const [tfaEmail, setTfaEmail] = useState("");
  const [signatureImage, setSignatureImage] = useState(null);
  const [signatureImage1, setSignatureImage1] = useState(null);
  const [getSubmit, setSubmit] = useState(false);
  const [tfaUrl, setTfaUrl] = useState(false);
  const [otpbtn, setOtpbtn] = useState(true);
  const [dataFromChild, setDataFromChild] = useState(false);
  const [filterApproverData, setFilterApproverData] = useState([]);
  const [approverNameInput, setApproverNameInput] = useState("");
  const [externalWalletBSCList, setExternalWalletBSCList] = useState([]);
  const [externalCoinBalance, setExterCoinBalance] = useState(0);
  const [externalCoinAmount, setExterCoinAmount] = useState(0);
  const [internalAmount, setInternalAmount] = useState(0);
  const [internalCoinApiAmount, setInternalCoinApiAmount] = useState(null);
  const [internalPrice, setInternalPrice] = useState(null);
  const [walletType, setWalletType] = useState('virtual');

  const [approveFile2, setFile2] = useState([]); // Initialize with an empty array
  console.log("🚀 ~ ContractForm ~ approveFile2:", approveFile2)
  const [files, setFiles] = useState([]);



  const [load, setloader] = useState(false);
  const [isTfaVarified, setIsTfaVarified] = useState(false);
  const userSessionData = JSON.parse(localStorage.getItem('user'));
  const [tfaCode, setTfaCode] = useState('');
  const [onChainCoins, setOnchainCoins] = useState([]);

  const handleTfaCode = async () => {
    const requestData = {
      token: tfaCode,
      email: userSessionData.data.user.email,
    };

    try {
      setisLoading(true);
      const res = await verifyTFAEmail(requestData);
      if (res) {
        setisLoading(false);
        if (res.data.verified === false) {
          setTfaCode('');
          toast.error('Incorrect TFA Code', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (res.data.verified === true) {
          setIsTfaVarified(true)
          toast.success('Verify TFA Successfully', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setisLoading(false);
          setTfaCode('');
          toast.error('Verify TFA failed', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {

        setisLoading(false);
        console.error('verifyTFA failed:', res);
      }
    } catch (error) {
      console.log("🚀 ~ handleTfaCode ~ error:", error)

      setisLoading(false);
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const getPDf1 = (value) => {
    setSubmit(false);
  };

  const getPDf = (value) => {
    setSubmit(true);
    setFile2((prevFiles) => [...prevFiles, value]); // Append new value to the existing array
  };

  const handleFileRemove = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove file by index
    toast.success("File Removed", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  function dataURLtoBlob1(dataUrl) {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const handleSignatureEnd = (dataUrl) => {
    const blob = dataURLtoBlob1(dataUrl);
    const fileName = "signature.png"; // You can set the desired file name
    const fileType = "image/png"; // Adjust the file type accordingly
    const file11 = new File([blob], fileName, { type: fileType });
    setSignatureImage(dataUrl);
    setSignatureImage1(file11);
  };



  const handleToggle = () => {
    setToggle((prev) => !prev);
  };

  const {
    REACT_APP_SMART_CHAIN,
    REACT_APP_ETHERIUM_CHAIN,
    REACT_APP_POLYGON_MATIC,
    REACT_APP_BSC_CONTRACT_ADDRESS,
    REACT_APP_MATIC_CONTRACT_ADDRESS,
    REACT_APP_ETH_CONTRACT_ADDRESS,
    REACT_APP_BSC_DEFAULT_TOKEN,
    REACT_APP_ETH_DEFAULT_TOKEN_ADDRESS,
    REACT_APP_MATIC__DEFAULT_TOKEN_ADDRESS,
    REACT_APP_BNB_CTY_COMISSION_ADDRESS,
    REACT_APP_BNB_USDT_COMISSION_ADDRESS,
    REACT_APP_MATIC_CTY_COMISSION_ADDRESS,
    REACT_APP_MATIC_USDT_COMISSION_ADDRES,
    REACT_APP_ETH_CTY_COMISSION_ADDRESS,
    REACT_APP_ETH_USDT_COMISSION_ADDRESS,
    REACT_APP_ADMIN_WALLET_ADDRESS_FOR_COMMISION,
  } = config;

  async function fetchBalance() {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      if (account.address !== undefined) {
        const connection = await web3.eth.getChainId();
        const chainId = Number(connection);
        // if (chain === REACT_APP_SMART_CHAIN) {
        //   setContractAddress(REACT_APP_BSC_CONTRACT_ADDRESS);
        //   setwalletCoin(REACT_APP_BSC_DEFAULT_TOKEN);
        // } else {
        //   setContractAddress(chain === REACT_APP_ETHERIUM_CHAIN ? REACT_APP_ETH_CONTRACT_ADDRESS : REACT_APP_MATIC_CONTRACT_ADDRESS);
        //   setwalletCoin(chain === REACT_APP_ETHERIUM_CHAIN ? REACT_APP_ETH_DEFAULT_TOKEN_ADDRESS : REACT_APP_MATIC__DEFAULT_TOKEN_ADDRESS);
        // }

        if (!account.address) {
          setIsError(false);
          setMsg("");
        }

        let tokenbalance = 0;
        if (chain === "BTC") {
          axios
            .post(
              `${API_URL}getBtcWalletBalance`,
              { walletAddress: btcAddress },
              {
                headers: authHeader(),
              }
            )
            .then((response) => {
              if (response.data.success === true) {
                tokenbalance = response.data.balance;
                setIsBtc(false);
              }
            });
        } else if (
          walletCoin &&
          account.address &&
          walletCoin !== "BNB" &&
          walletCoin !== "ETH" &&
          walletCoin !== "MATIC"
        ) {
          try {
            const tokenContract = new web3.eth.Contract(Abi, walletCoin);
            tokenbalance = await tokenContract.methods
              .balanceOf(account.address)
              .call();
          } catch (error) {
            console.log(error);
          }
        } else {
          await web3.eth
            .getBalance(account.address)
            .then((balance) => {
              const balanceInWei = balance.toString(); // The balance is returned in wei (smallest unit of ether).
              tokenbalance = web3.utils.fromWei(balanceInWei, "ether"); // Convert to Ether
            })
            .catch((error) => {
              if (error.message === "Network Error") {
                //  setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
              } else {
                // setMsg(`CatchError:${error}${error?.message}`);
              }
            });
        }

        if (Number(tokenbalance) === 0 && account.address) {
          setIsError(true);
          setstatusCode(400);
          setMsg("Not Enough Balance to create contract");
        } else {
          setIsError(false);
          setMsg("");
        }
        setTokenBalance(Number(tokenbalance));
      } else if (chain === "BTC") {
        axios
          .post(
            `${API_URL}getBtcWalletBalance`,
            { walletAddress: btcAddress },
            {
              headers: authHeader(),
            }
          )
          .then((response) => {
            if (response.data.success === true) {
              const tokenbalance = response.data.balance;
              setIsBtc(false);
              if (Number(tokenbalance) === 0) {
                setIsError(true);
                setstatusCode(400);
                setMsg("Not Enough Balance to create contract");
              } else {
                setIsError(false);
                setMsg("");
              }
              setTokenBalance(Number(tokenbalance));
            }
          })
          .catch((error) => {
            setIsBtc(false);
            setIsError(false);
            if (error.message === "Network Error") {
              // setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
            } else {
              // setMsg(`CatchError:${error}${error?.message}`);
            }
          });
      } else {
        setIsError(false);
        setMsg("");
      }
    }
    if (account.address === undefined) {
      if (benficiaryToggle === "external") {
        setIsError(true);
        setMsg("Please connect the wallet");
      }
    }
  }

  // async function fetchBalance() {
  //   try {
  //     if (!window.ethereum) {
  //       return;
  //     }

  //     const web3 = new Web3(window.ethereum);

  //     if (account.address === undefined) {
  //       if (benficiaryToggle === "external") {
  //         setIsError(true);
  //         setMsg("Please connect the wallet");
  //       }
  //       return;
  //     }

  //     const connection = await web3.eth.getChainId();
  //     const chainId = Number(connection);
  //     let tokenbalance = 0;

  //     if (chain === "BTC") {
  //       const response = await axios.post(
  //         `${API_URL}getBtcWalletBalance`,
  //         { walletAddress: btcAddress },
  //         { headers: authHeader() }
  //       );

  //       if (response.data.success === true) {
  //         tokenbalance = response.data.balance;
  //         setIsBtc(false);
  //       }
  //     } else if (walletCoin && !["BNB", "ETH", "MATIC"].includes(walletCoin)) {
  //       const tokenContract = new web3.eth.Contract(Abi, walletCoin);
  //       tokenbalance = await tokenContract.methods
  //         .balanceOf(account.address)
  //         .call();
  //     } else {
  //       const balance = await web3.eth.getBalance(account.address);
  //       const balanceInWei = balance.toString();
  //       tokenbalance = web3.utils.fromWei(balanceInWei, "ether");
  //     }

  //     if (Number(tokenbalance) === 0) {
  //       setIsError(true);
  //       setstatusCode(400);
  //       setMsg("Not Enough Balance to create contract");
  //     } else {
  //       setIsError(false);
  //       setMsg("");
  //     }

  //     setTokenBalance(Number(tokenbalance));
  //   } catch (error) {
  //     console.error(error);

  //     if (error.message === "Network Error") {
  //       // setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
  //     } else {
  //       // setMsg(`CatchError:${error}${error?.message}`);
  //     }
  //   }
  // }

  useEffect(() => {
    //check account address if available then hide error message
    if (account.address !== undefined) {
      setIsError(false);
      setMsg("");
    }

    if (account.address !== undefined) {
      setisWallet(true);
      return setwalletApi("createContractExternal");
    }
    fetchBalance();
    return setisWallet(false);
  }, [account]);

  const fetchExternalBSCCoins = async () => {
    if (account.address !== undefined) {
      const wallet_address = account.address;
      externalBSCCoins(wallet_address)
        .then((response) => {
          if (response.status === 200) {
            setisLoading(false);
            setstatusCode(200);
            const externalBSCCoinList = response.data.data.map((i) => ({
              symbol: i.symbol,
              quantity: i.quantity,
            }));
            // setExternalBSCCoinsData(externalBSCCoinList);
            setExternalWalletBSCList(response?.data?.data);
          }
        })
        .catch((error) => {
          setisLoading(false);
          setstatusCode(400);
          if (error.message === "Network Error") {
            // setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
          } else {
            setMsg(`CatchError External:${error}${error?.message}`);
            toast.error(`CatchError External 12:${error}${error?.message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setTimeout(() => {
            setMsg("");
          }, 5000);
        });
    } else {
      setisLoading(false);
      toast.error("Your Wallet is not connected", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        setMsg("");
      }, 3000);
    }
  };

  const fectchOnchainCoins = async () => {
    try {
      setisLoading1(true)
      const res = await userBalanceInternalWalletSubWallet()
      setOnchainCoins(res?.data)
      setisLoading1(false)

    } catch (error) {
      console.log("🚀 ~ useEffect ~ error:", error)
      setisLoading1(false)
    }
  }


  useEffect(() => {
    fectchOnchainCoins()
  }, [])

  useEffect(() => {
    if (account.address !== undefined) {
      fetchExternalBSCCoins();
    }
  }, [account?.address, isWallet]);

  const exCoins = ["BNB", "MATIC", "ETH"];

  const dispatch = useDispatch();
  let defaultOptions = [];
  let ethirumOptions = [];
  let bsc = [];
  let maticOptions = [];

  const getContractDetails = () => {
    setisLoading1(true);
    dispatch(fetchAllCurrencies1())
      .then(() => {
        setisLoading1(false);
      })
      .catch((error) => {
        // Handle any errors if needed
        setisLoading1(false);
      });
  };
  useEffect(() => {
    getContractDetails();
  }, [dispatch]);

  const { coinList1 } = useSelector((state) => ({
    coinList1: state.coinList1.data,
  }));

  const SmallLabel = ({ text, smallText }) => (
    <span>
      {text}{" "}
      <small>
        <b>{smallText}</b>
      </small>
    </span>
  );

  useEffect(() => {
    const updateOptions = async () => {
      const filteredOptions = coinList1?.filter(item => !["LTX", "WEN", "SOLANA", "SoLpy", "USDT (TRC20)", "TRON"].includes(item?.symbol));
      const filteredOptionsOnchain = onChainCoins?.data?.filter(item => !["LTX", "WEN", "SOLANA", "SoLpy", "USDT (TRC20)", "TRON"].includes(item?.symbol));

      const defaultOptions = filteredOptions.map(item => ({
        value: item?.symbol,
        label: <SmallLabel text={item?.symbol === "META" ? "MET$" : item?.symbol} smallText={item?.quantity} />,
        price: item?.price
      }));
      const defaultOptionsOnchain = filteredOptionsOnchain?.map(item => ({
        value: item?.symbol,
        label: <SmallLabel text={item?.symbol === "META" ? "MET$" : item?.symbol} smallText={item?.quantity} />,
        price: item?.price
      }));

      if (walletType === "onChain") {
        setOptions(defaultOptionsOnchain);
      } else {
        setOptions(defaultOptions);
      }

      const newDataArray = externalWalletBSCList.map((item2) => {
        const matchingItem1 = externalWalletAddresses.find(
          (item1) => item1.symbol === item2.symbol
        );

        return {
          value: matchingItem1 ? matchingItem1.value : "",
          label: <SmallLabel text={item2.symbol} smallText={item2.quantity} />,
          priceDta: item2,
        };
      });

      const bsc = newDataArray.map((newData) => {
        return {
          value: newData?.value,
          label: (
            <SmallLabel
              text={newData?.label?.props?.text}
              smallText={newData?.label?.props?.smallText}
            />
          ),
          price: newData,
        };
      });

      if (benficiaryToggle === "external") {
        setOptions(bsc);
      }
    };

    if (coinList1.length > 0 || onChainCoins.length > 0) {
      updateOptions();
    }
  }, [coinList1, walletType, onChainCoins, dispatch]);

  useEffect(() => {
    if (benficiaryToggle === "external") {
      // if (chain === REACT_APP_SMART_CHAIN) {
      setOptions(bsc);
      // setExternalOptions(bsc);
      setContractAddress(REACT_APP_BSC_CONTRACT_ADDRESS);
      setwalletCoin(REACT_APP_BSC_DEFAULT_TOKEN);
      setExternalCoin("BNB");
      setTokenBalance(0);
      fetchBalance();
      // }
      // else if (chain === REACT_APP_ETHERIUM_CHAIN) {
      //   setOptions(ethirumOptions);
      //   setExternalCoin("");
      //   setExternalCoin("ETH");
      //   setContractAddress(REACT_APP_ETH_CONTRACT_ADDRESS);
      //   setwalletCoin(REACT_APP_ETH_DEFAULT_TOKEN_ADDRESS);
      //   setTokenBalance(0);
      //   fetchBalance();
      // } else if (chain === REACT_APP_POLYGON_MATIC) {
      //   setOptions(maticOptions);
      //   setExternalCoin("");
      //   setExternalCoin("MATIC");
      //   setContractAddress(REACT_APP_MATIC_CONTRACT_ADDRESS);
      //   setwalletCoin(REACT_APP_MATIC__DEFAULT_TOKEN_ADDRESS);
      //   setTokenBalance(0);
      //   fetchBalance();
      // } else if (chain === "BTC") {
      //   setOptions(defaultOptions);
      //   setExternalCoin("");
      //   setExternalCoin("BTC");
      //   setIsBtc(true);
      // }
    } else {
      setOptions(defaultOptions);
    }
  }, [chain]);
  let tokens = null;
  if (chain === REACT_APP_SMART_CHAIN) {
    tokens = [
      { value: "USDT (BEP20)", label: "USDT (BEP20)" },
      { value: "CTY", label: "CTY" },
    ];
  } else {
    tokens = [{ value: "USDT (BEP20)", label: "USDT (BEP20)" }];
  }

  const fileInput = React.createRef();
  const user = JSON.parse(localStorage.getItem("user"));
  const [bnNames, setbnNames] = useState([]);
  const [approverData, setapproverData] = useState([]);
  // const [isValid, setisValid] = useState(null);

  useEffect(() => {
    getApprover()
      .then((res) => {
        const arr = res.data.user.map((i) => ({
          id: i._id,
          address: i.address,
          value: i.name,
          label: i.name,
        }));
        setapproverData(arr);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          // setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
        } else {
          // setMsg(`CatchError:${error}${error?.message}`);
        }
      });
  }, []);

  useEffect(() => {
    // Filter approverData based on user input and set as filterOptions
    if (approverNameInput) {
      const filteredOptions = approverData.filter(
        (option) =>
          option.label.toLowerCase() === approverNameInput.toLowerCase()
      );
      setFilterApproverData(filteredOptions);
    }
  }, [approverNameInput]);

  function checkIfString(value) {
    const characterPattern = /^[A-Za-z]+$/;
    return characterPattern.test(value);
  }
  const CoinConversion = async (amount, coin) => {

    let coinType = coin;
    if (coinType === null) {
      coinType = externalCoin;
    }

    let coinVal = null;
    if (checkIfString(coinType)) {
      coinVal = options.filter((token) => token.label === coinType);
    } else {
      coinVal = options.filter((token) => token.value === coinType);
    }
    coinType = coinVal[0].label;
    setwalletCoin(coinVal[0].value);
    setloader(false);
    return

  };
  const OtpSend = async () => {
    axios
      .post(`${API_URL}contract-otp`, "", { headers: authHeader() })
      .then((response) => {
        setisLoading(false);
        setstatusCode(200);
        setMsg(response.data.message);
        setTimeout(() => {
          setMsg("");
        }, 3000);
        return response;
      }).catch = (error) => {
        setisLoading(false);
        setstatusCode(400);
        setMsg(`Catch error:,\n ${error}`);
        setTimeout(() => {
          setMsg("");
        }, 3000);
      };
  };
  const SendOtp = () => {
    setisLoading(true);
    setOtp(true);
    OtpSend();
  };
  const ResendOtp = () => {
    setisLoading(true);
    setOtp(true);
    OtpSend();
    setResendOtp(false);
    setcount(count + 1);
    setMinutes(1);
    setSeconds(59);
  };
  const saveContract = async (formData) => {
    console.log("saveContract", formData);

    setisLoading(true);
    let ApiRoute;


    if (walletType == 'virtual') {
      ApiRoute = "createContract"

    } else {
      ApiRoute = "createContractOnchain"
    }

    if (benficiaryToggle === "external") {
      ApiRoute = "createContractExternal";
    }
    axios
      .post(API_URL + ApiRoute, formData, {
        headers: authHeader(),
      })
      .then(
        (response) => {
          if (response.status === 200) {
            setMinutes(0);
            setSeconds(0);
            setisLoading(false);
            setstatusCode(200);
            toast.success("Contract Created Successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setMsg("Contract Created Successfully!");
            dispatch(getTotalBalance());
            setOtp(false);
            setName("");
            setAmount("");
            setCoin(benficiaryToggle === "external" ? externalCoin : "USDT");
            setUSDCoin("");
            setCommissionToken("");
            setAddress(user1.data.user.address);
            setFrom(`${user1.data.user.firstName} ${user1.data.user.lastName}`);
            setBenficeryName("");
            setBenficeryAddress("");
            setDate("");
            setFiles(null);
            setApproverName("");
            setApproverAddress("");
            setOtp1("");
            setTfaUrl(false);
            setOtpbtn(true);
            // resetForm({ values: '' });
            setTimeout(() => {
              setMsg("");
            }, 9000);
          } else if (response.status === 400) {
            setMinutes(0);
            setSeconds(0);
            setisLoading(false);
            setMsg(response.data.Message);
            toast.error(response.data.Message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setstatusCode(400);
            setOtp(false);
            setName("");
            setAmount("");
            setCoin(benficiaryToggle === "external" ? externalCoin : "USDT");
            setUSDCoin("");
            setCommissionToken("");
            setAddress(user1.data.user.address);
            setFrom(`${user1.data.user.firstName} ${user1.data.user.lastName}`);
            setBenficeryName("");
            setBenficeryAddress("");
            setDate("");
            setFiles(null);
            setApproverName("");
            setApproverAddress("");
            setOtp1("");
            setTfaUrl(false);
            setOtpbtn(true);
            // resetForm({ values: '' });
            setTimeout(() => {
              setMsg("");
            }, 3000);
          }
          return response;
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response.data.status === 400) {
            setisLoading(false);
            setstatusCode(400);
            // console.log('response >>>>>>>>>> 3', error);
            setMsg(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.Error
            );
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.Error,
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
            if (error.response.data.Message === "Invalid Otp") {
              setResendOtp(true);
              setcount(count + 1);
              setTimeout(() => {
                setMsg("");
              }, 3000);
            } else {
              setOtp(false);
              setName("");
              setAmount("");
              setCoin(benficiaryToggle === "external" ? externalCoin : "USDT");
              setUSDCoin("");
              setCommissionToken("");
              setAddress(user1.data.user.address);
              setFrom(
                `${user1.data.user.firstName} ${user1.data.user.lastName}`
              );
              setBenficeryName("");
              setBenficeryAddress("");
              setDate("");
              setFiles(null);
              setApproverName("");
              setApproverAddress("");
              setOtp1("");
              setTfaUrl(false);
              setOtpbtn(true);
              setMinutes(0);
              setSeconds(0);
              // resetForm({ values: '' });
              setTimeout(() => {
                setMsg("");
              }, 4000);
            }
          } else {
            setisLoading(false);
            setName("");
            setAmount("");
            setCoin(benficiaryToggle === "external" ? externalCoin : "USDT");
            setUSDCoin("");
            setCommissionToken("");
            setAddress(user1.data.user.address);
            setFrom(`${user1.data.user.firstName} ${user1.data.user.lastName}`);
            setBenficeryName("");
            setBenficeryAddress("");
            setDate("");
            setFiles(null);
            setApproverName("");
            setApproverAddress("");
            setOtp1("");
            setTfaUrl(false);
            setOtpbtn(true);
            setstatusCode(400);
            setMsg(message);
            toast.error(message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            // resetForm({ values: '' });
            setTimeout(() => {
              setMsg("");
            }, 3000);
          }
          // return Promise.reject();
        }
      )
      .catch((error) => {
        setMinutes(0);
        setSeconds(0);
        setResendOtp(false);
        setName("");
        setAmount("");
        setCoin(benficiaryToggle === "external" ? externalCoin : "USDT");
        setUSDCoin("");
        setCommissionToken("");
        setAddress(user1.data.user.address);
        setFrom(`${user1.data.user.firstName} ${user1.data.user.lastName}`);
        setBenficeryName("");
        setBenficeryAddress("");
        setDate("");
        setFiles(null);
        setApproverName("");
        setApproverAddress("");
        setOtp1("");
        setTfaUrl(false);
        setOtpbtn(true);
        setisLoading(false);
        setstatusCode(400);
        setMsg(`Catch error:,\n ${error}`);
        toast.error(`Catch error:,\n ${error}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          setMsg("");
        }, 5000);
      });
  };
  async function exernaWallet(formData) {
    setisLoading(true);
    if ((commissionToken === null ? externalCoin : commissionToken) === "BTC") {
      if (amount1 < tokenBalance) {
        setstatusCode(400);
        setloading(false);
        setMsg("You dont have sufficient token balance");
        toast.error("You dont have sufficient token balance", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          setMsg("");
        }, 3000);
      } else {
        saveContract(formData);
        setMsg("Transaction Successfully Done!!!");
        toast.success("Transaction Successfully Done", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setloading(false);
        setstatusCode(200);
      }
    } else {
      try {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3.utils.toHex(Number(chain)) }],
        });

        const contractCustodiy = new web3.eth.Contract(
          ContractAbi,
          contractAddress
        );
        // const ownerAddress = await contractCustodiy.methods.contractOwner().call();
        const approvers = approverAddress;
        const currentAddress = account.address; // External wallet address
        // const userAdress = JSON.parse(localStorage.getItem('user'));
        const RecipientAddress = benficeryAddress; // Internal wallet address
        const ipfs = "";
        // if (formData.file) {
        //    ipfs = formData.file.join(', ');
        // }

        // console.log(ipfs);
        // console.log('adding ownerAddress as a approval ====>>>>>', ownerAddress);

        try {
          // eslint-disable-next-line radix
          const value = parseInt(web3.utils.toWei(amount1, "ether"));

          if (amount1 > 0) {
            setloading(false);
            // console.log('Input value in wei ====>>>>>', value);
            // eslint-disable-next-line max-len
            // console.log(contractCustodiy.methods);
            // console.log(commissionToken, externalCoin);
            // const valuesToken = commissionToken === undefined ? externalCoin : commissionToken;
            const valuesToken =
              commissionToken === "USDT"
                ? REACT_APP_BNB_USDT_COMISSION_ADDRESS
                : REACT_APP_BNB_CTY_COMISSION_ADDRESS;
            // For Coin transactions
            // if ((valuesToken === 'BNB') || (valuesToken === 'MATIC') || (valuesToken === 'ETH')) {
            //   console.log('******************');
            //   const txObject = {
            //     from: currentAddress,
            //     to: contractAddress,
            //     value: value.toString(), // Amount of BNB to send with the transaction
            //     gas: 300000, // Gas limit for the transaction (adjust as needed)
            //     // gasPrice: web3.utils.toWei('50', 'gwei'), // Gas price in Gwei (adjust as per network congestion)
            //   };

            //   const receipt = await contractCustodiy.methods.lockBnb(RecipientAddress, approvers, date1, ipfs).send(txObject);

            //   const transactionIndexVal = await contractCustodiy.methods.transactionIdCounter().call();
            //   const finalVal = formData;
            //   finalVal.hash = receipt.transactionHash;
            //   finalVal.trasaction_id = parseInt(transactionIndexVal, 16) - 1;
            //   finalVal.coin = (finalVal.token ? externalCoin : finalVal.token);
            //   saveContract(finalVal);
            //   setMsg(`Transaction Successfully Done!!! Here is Trasaction Hash,\n ${receipt.transactionHash}`);
            //   toast.success(`Transaction Successfully Done!!! Here is Trasaction Hash,\n ${receipt.transactionHash}`, {
            //     position: toast.POSITION.TOP_RIGHT,
            //   });
            //   setloading(false);
            //   setstatusCode(200);
            //   console.log('receipt');
            //   console.log(receipt);
            // } else {

            // }
            // if ((walletCoin !== 'BNB') || (walletCoin !== 'MATIC') || (walletCoin !== 'ETH')) {
            // console.log('^^^^^^^^^^^^^^^^^^');
            // For token transactions
            const tokenAddress = valuesToken; // walletCoin;
            const tokenContract = new web3.eth.Contract(Abi, tokenAddress);
            const tokenbalance = await tokenContract.methods
              .balanceOf(currentAddress)
              .call();
            const tokenConvertBalance = web3.utils.fromWei(
              tokenbalance,
              "ether"
            );
            if (tokenConvertBalance > amount1) {
              const tokenContractResult = await tokenContract.methods
                .increaseAllowance(contractAddress, value.toString())
                .send({
                  from: currentAddress,
                  gasPrice: web3.utils.toWei("10", "gwei"),
                  gasLimit: 6000000,
                });
              setMsg("Please Confirm Transaction Approval");
              const contractCustodiyResult = await contractCustodiy.methods
                .lockTokens(
                  RecipientAddress,
                  tokenAddress,
                  value.toString(),
                  approvers,
                  date1,
                  ipfs
                )
                .send({
                  from: currentAddress,
                  gasPrice: web3.utils.toWei("10", "gwei"),
                  gasLimit: 6000000,
                });
              const transactionIndexVal = await contractCustodiy.methods
                .transactionIdCounter()
                .call();

              // const transactionIndexVal = parseInt(contractCustodiyResult.jahangir) - 1;
              const finalVal = formData;
              finalVal.hash = contractCustodiyResult.transactionHash;
              finalVal.trasaction_id = parseInt(transactionIndexVal, 16) - 1;
              finalVal.coin =
                finalVal.token === null ? externalCoin : finalVal.token;
              saveContract(finalVal);
              setMsg(
                `Transaction Successfully Done!!! Here is Trasaction Hash,\n ${contractCustodiyResult.transactionHash}`
              );
              toast.success(
                `Transaction Successfully Done!!! Here is Trasaction Hash,\n ${contractCustodiyResult.transactionHash}`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              setloading(false);
              setstatusCode(200);
            } else {
              setstatusCode(400);
              setloading(false);
              setMsg("You dont have sufficient token balance");
              setTimeout(() => {
                setMsg("");
              }, 3000);
            }
            // }
            // else {

            // }
          } else {
            setloading(false);
            setstatusCode(400);
            setMsg("Please Enter Correct Amount");
            toast.error("Please Enter Correct Amount", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(() => {
              setMsg("");
            }, 3000);
          }
        } catch (error) {
          setloading(false);
          setisLoading(false);
          setstatusCode(400);
          console.log(error);
          setMsg(`Error approving token:,\n ${error}`);
          toast.error(`Error approving token:,\n ${error}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      } catch (error) {
        setloading(false);
        setstatusCode(400);
        setisLoading(false);
        console.log(error);
        setMsg(`Async function encountered an error:,\n ${error}`);
        toast.error(`Async function encountered an error:,\n ${error}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          setMsg("");
        }, 6000);
      }
    }
  }

  const sendContractCreationComissionToAdmin = async (
    comissionFee,
    comissionTokenSymbol,
    formData
  ) => {
    // console.log(comissionFee, comissionTokenSymbol);
    setisLoading(true);
    const web3 = new Web3(window.ethereum);
    if (comissionFee <= 0) {
      return { status: true };
    }
    const connection = await web3.eth.getChainId();
    const chainId = Number(connection);

    let tokenAddress = null;
    if (
      chainId === REACT_APP_ETHERIUM_CHAIN &&
      (comissionTokenSymbol === "CTY" || comissionTokenSymbol === "USDT")
    ) {
      tokenAddress =
        comissionTokenSymbol === "CTY"
          ? REACT_APP_ETH_CTY_COMISSION_ADDRESS
          : REACT_APP_ETH_USDT_COMISSION_ADDRESS;
    } else if (
      chainId === REACT_APP_SMART_CHAIN &&
      (comissionTokenSymbol === "CTY" || comissionTokenSymbol === "USDT")
    ) {
      tokenAddress =
        comissionTokenSymbol === "CTY"
          ? REACT_APP_BNB_CTY_COMISSION_ADDRESS
          : REACT_APP_BNB_USDT_COMISSION_ADDRESS;
    } else {
      // console.log(REACT_APP_MATIC_CTY_COMISSION_ADDRESS, REACT_APP_MATIC_USDT_COMISSION_ADDRES);
      tokenAddress =
        comissionTokenSymbol === "CTY"
          ? REACT_APP_MATIC_CTY_COMISSION_ADDRESS
          : REACT_APP_MATIC_USDT_COMISSION_ADDRES;
    }
    switch (comissionTokenSymbol) {
      case "CTY":
        try {
          const adminWalletAddressForCommission =
            "0x710dcda140962b487e6934e7fa8fa6be62a5c61e";
          // const tokenAddress = '0x592Ed02b382C7d682851Ff241c016c4550E11E5a';
          const instance = new web3.eth.Contract(Abi, tokenAddress);
          const amount = web3.utils.toWei(
            `${comissionFee.toFixed(6)}`,
            "ether"
          );
          const receipt = await instance.methods
            .transfer(REACT_APP_ADMIN_WALLET_ADDRESS_FOR_COMMISION, amount)
            .send({ from: account.address });
          if (!receipt.status) {
            setisLoading(false);
            setstatusCode(400);
            setMsg("Gas fees transfer failed");
            toast.error("Gas fees transfer failed", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setOtp(false);
            return false;
          }
          exernaWallet(formData);
          return receipt;
        } catch (e) {
          console.log(e);
          setisLoading(false);
          return { status: false };
        }
      case "USDT":
        try {
          const adminWalletAddressForCommission =
            "0x710dcda140962b487e6934e7fa8fa6be62a5c61e";
          // const tokenAddress = '0x32B56a0Fd9164808132C691f7A15C7Ef04BaCD79';
          const instance = new web3.eth.Contract(Abi, tokenAddress);
          // const amount = await web3.utils.toWei(`${comissionFee.toFixed(6)}`, 'ether');
          const amount = await web3.utils.toWei("1", "ether");
          const receipt = await instance.methods
            .transfer(REACT_APP_ADMIN_WALLET_ADDRESS_FOR_COMMISION, amount)
            .send({
              from: account.address,
              gasPrice: web3.utils.toWei("10", "gwei"),
              gasLimit: 6000000,
            });
          if (!receipt.status) {
            setisLoading(false);
            setstatusCode(400);
            setMsg("Gas fees transfer failed");
            toast.error("Gas fees transfer failed", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setOtp(false);
            return false;
          }
          exernaWallet(formData);
          return receipt;
        } catch (e) {
          console.log(e);
          setisLoading(false);
          return { status: false };
        }
      default:
        return { status: false };
    }
  };

  const AddContract = async () => {
    setisLoading(true);
    setIsError(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const userAdress = JSON.parse(localStorage.getItem("user"));
    let RecipientAddress = userAdress.data.user.address;

    if (benficiaryToggle === "external") {
      if (chain === "BTC") {
        RecipientAddress = btcAddress;
      } else {
        RecipientAddress = account.address;
      }
    }

    const web3 = new Web3(window.ethereum);
    const connection = await web3.eth.getChainId();
    const chainId = Number(connection);

    const formData = {
      amount: amount1,
      name: name1,
      comissionToken: commissionToken,
      otp: otp1,
      date: date1,
      sender: RecipientAddress,
      beneficiary: benficeryAddress,
      approver: approverAddress,
      symbol: coin1 === null ? externalCoin : coin1 === 'META' ? 'MET$' : coin1,
      transfer: account.address ? account.address : user1.data.user.address,
      chainId,
      adminFee: null,
      hash: null,
      trasaction_id: null,
      Beneficiary_wallet: benficeryDocsAddress,
    };

    // Create a FormData instance and append all the form data
    const formDataPayload = new FormData();

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        formDataPayload.append(key, formData[key]);
      }
    }

    // Append each file from the approveFile2 array to FormData under the same "File" key
    if (approveFile2 && approveFile2.length > 0) {
      approveFile2.forEach((file) => {
        formDataPayload.append("File", file); // Append each file under the same "File" key
      });
    }

    if (benficiaryToggle === "external") {
      if (isWallet) {
        approveExternalWalletOtp(otp1)
          .then(
            (response) => {
              if (response.status === 200) {
                // Handle success and further operations
              }
            },
            (error) => {
              // Handle error
            }
          )
          .catch((error) => {
            // Handle error
          });
      } else {
        setisLoading(false);
        setstatusCode(400);
        setMsg("Your Wallet is not connected");
        toast.error("Your Wallet is not connected", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setOtp(false);
        setTimeout(() => {
          setMsg("");
        }, 3000);
      }
    } else {
      // Send the formDataPayload (FormData object) to the server
      saveContract(formDataPayload);
    }
  };



  const handleDataFromChild = (data) => {
    if (data === true) {
      AddContract();
      setDataFromChild(data);
    }
  };

  const handleInputChange = (name) => {
    setsearchLoad(true);

    if (name.length >= 2) {
      getBeneficiaryName({ name })
        .then((res) => {
          setsearchLoad(false);

          const arr = res.data.user.map((i) => ({
            id: i._id,
            address: i.address,
            value: i.name,
            label: i.name,
          }));

          setsearchLoad(false);

          // Case-insensitive comparison
          const matchingOptions = arr.filter(
            (option) => option.value.toLowerCase() === name.toLowerCase()
          );

          setbnNames(matchingOptions);
        })
        .catch((error) => {
          setsearchLoad(false);
          setstatusCode(400);

          if (error.message === "Network Error") {
            // setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
          } else {
            setMsg(`CatchError:${error}${error?.message}`);
            toast.error(`CatchError:${error}${error?.message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          setTimeout(() => {
            setMsg("");
          }, 5000);
        });
      // } else {
      //   setbnNames([]); // Clear options when input length is less than 2
    }

    setsearchLoad(false);
  };

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: colorAccent,
      "&:hover": {
        backgroundColor: "#4ce1b65e",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: colorAccent,
    },
  }));
  const handleBtcChange = (event) => {
    const inputAddress = event.target.value.trim();
    setBtcAddress(event.target.value);
  };

  useEffect(() => {
    if (btcAddress === "") {
      setValidBtcAddress(null); // Reset validation status when the input is empty
    } else {
      setValidBtcAddress(validate(btcAddress, Network.testnet));
    }
  }, [btcAddress]);

  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0 so need to add 1 to make it 1!
  const yyyy = today.getFullYear();

  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }
  const minDate = `${yyyy}-${mm}-${dd}`;

  const walletTypeOptions = [
    {
      label: "Virtual",
      value: "virtual"
    },
    {
      label: "Onchain",
      value: "onChain"
    }
  ]

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Panel lg={12} title="New Contract" closeBtn minusBtn refreshLoad>
        <Formik
          initialValues={{
            name: "",
            amount: "",
            coin: benficiaryToggle === "external" ? externalCoin : "USDT",
            convertedAmount: uSDCoin,
            commissionToken: "USDT",
            from: `${user.data.user.firstName} ${user.data.user.lastName}`,
            address: user.data.user.address,
            benficeryName: "",
            date: "",
            upload: "",
            approverName: "",
            approverAddress: "",
            benficeryAddress: "",
            otp: "",
            isBeneficiary: true,
          }}
          onSubmit={(values, { resetForm }) => {
            // console.log(values);
            // if (addressValid) {
            if (otp1 === "") {
              if (benficiaryToggle === "external") {
                if (isWallet) {
                  SendOtp();
                } else {
                  setisLoading(false);
                  setstatusCode(400);
                  setMsg("Your Wallet is not connected");
                  toast.error("Your Wallet is not connected", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  setOtp(false);
                }
              } else {
                SendOtp();
              }
            } else if (dataFromChild === false) {
              if (user1.data.user.TFA === true) {
                setOtpbtn(false);
                setTfaUrl(true);
                setTfaEmail(user1.data.user.email);
              } else {
                AddContract();
              }
            } else {
              console.log("Else");
              // AddContract(values, { resetForm });
            }
            // }
          }}
          validate={(values) => {
            const errors = {};
            const regex = /^[0-9\b]+$/;
            const negRegex = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
            if (!name1) {
              errors.name = "Name is Required!!";
            }
            if (!amount1) {
              // console.log(coin1);
              errors.amount = "Amount is Required!!";
            } else if (
              ["ETH", "BNB", "MATIC"].includes(
                coin1 === null ? externalCoin : coin1
              )
            ) {
              if (!negRegex.test(amount1)) {
                errors.amount = "Amount must be in digit e.g 0.12,5";
              }
            } else if (
              !["ETH", "BNB", "MATIC"].includes(
                coin1 === null ? externalCoin : coin1
              )
            ) {
              if (!regex.test(amount1)) {
                errors.amount =
                  "Amount must be in digit and should be greater then 0 e.g 10";
              }
            }
            if (coin1) {
              if (amount1 === null) {
                errors.amount = "First Enter Amount!!";
              }
            }
            if (toggle) {
              const web3 = new Web3(window.ethereum);
              const isValid = web3.utils.isAddress(benficeryAddress);
              if (!benficeryAddress) {
                errors.benficeryAddress = "Benficery Address is Required!!";
              } else if (isValid === false) {
                errors.benficeryAddress =
                  "Please Enter Valid Benficery Address!!";
              }
            }

            if (benficiaryToggle === "external") {
              if (!approverName) {
                errors.approverName = "Approver Name is Required!!";
              }
            }

            if (!date1) {
              errors.date = "Date is Required!!";
            }
            // if (files === null) {
            //   errors.files = "File is Required!!";
            // }
            if (files) {
              if (approveFile2 === null) {
                errors.siganture = "Sigantured Doc is Required!!";
              }
            }
            if (otp) {
              const otpRegex = /^[0-9\b]+$/;
              if (!otp1) {
                errors.otp = "Otp is Required!!";
              } else if (!otpRegex.test(otp1)) {
                errors.otp = "Invalid Otp!! Must be In digits";
              }
            }

            return errors;
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <form onSubmit={handleSubmit}>
                <StyledAlert
                  variant={statusCode === 200 ? "success" : "danger"}
                  show={msg !== ""}
                  style={{
                    marginTop: "1rem",
                    width: "100%",
                    textAlign: "center",
                  }}
                  onClose={() => {
                    setMsg("");
                  }}
                  dismissible
                >
                  {msg}
                </StyledAlert>
                <div className="row mb-2">
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <InputDiv>
                      <Label htmlFor="email" className="mt-3">
                        Name
                      </Label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <ChatSearchInput
                          name="name"
                          type="text"
                          placeholder="Enter name of contract"
                          value={name1}
                          // onChange={handleChange}
                          onChange={(e) => setName(e.target.value)}
                          onBlur={handleBlur}
                          className={errors.name && touched.name && "error"}
                        />
                        {errors.name && touched.name && (
                          <Error className="input feedback">
                            {errors.name}
                          </Error>
                        )}
                      </div>
                    </InputDiv>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <Label
                      className="text-white"
                      style={{ fontSize: "smaller" }}
                      htmlFor="email"
                    >
                      Coins
                    </Label>
                    {isLoading1 ? (
                      <div>
                        <Spinner style={{ color: colorAccent }} />
                      </div>
                    ) : (
                      <CustomSelect
                        // name="coin"
                        value={coin1}
                        onChange={(value) => {

                          setCoin(value.value);
                          setInternalPrice(value?.price)
                          setExterCoinBalance(value?.label?.props?.text);
                          setExterCoinAmount(value?.price?.priceDta?.price);
                          setInternalAmount(value?.label?.props?.smallText);
                          // CoinConversion(amount1, value.value);
                        }}
                        options={options}
                        isSearchable
                      />
                    )}
                    <Label>{ }</Label>
                    <Label>{ }</Label>
                    {benficiaryToggle === "external" ? (
                      <Label>{tokenBalance}</Label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <Label
                      className="text-white"
                      style={{ fontSize: "smaller" }}
                      htmlFor="email"
                    >
                      Wallet Type
                    </Label>

                    <CustomSelect
                      // name="coin"
                      value={walletType}
                      onChange={(value) => {
                        setWalletType(value.value);
                      }}
                      options={walletTypeOptions}
                    />

                    <Label>{ }</Label>
                    <Label>{ }</Label>
                    {benficiaryToggle === "external" ? (
                      <Label>{tokenBalance}</Label>
                    ) : (
                      ""
                    )}
                  </div>


                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <InputDiv>
                      <Label htmlFor="email" className="mt-3">
                        Amount
                      </Label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <ChatSearchInput
                          name="amount"
                          type="text"
                          placeholder="Enter your amount"
                          value={amount1}
                          onChange={(value) => {
                            setAmount(value.target.value);
                            if (coin1 !== "") {
                              return CoinConversion(value.target.value, coin1);
                            }
                          }}
                          onBlur={handleBlur}
                          className={errors.amount && touched.amount && "error"}
                        />
                        {errors.amount && touched.amount && (
                          <Error className="input feedback">
                            {errors.amount}
                          </Error>
                        )}
                      </div>
                    </InputDiv>
                  </div>

                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <InputDiv>
                      <Label htmlFor="email" className="mt-3">
                        Converted Amount
                      </Label>
                      {load && benficiaryToggle !== "external" ? (
                        <span
                          style={{
                            color: "#4ce1b6",
                            fontSize: "14px",
                            marginTop: "20px",
                          }}
                        >
                          Loading.....
                        </span>
                      ) : coin1 || externalCoin ? (
                        <ChatSearchInput
                          // name="convertedAmount"
                          type="text"
                          placeholder="$0"
                          disabled
                          value={
                            benficiaryToggle === "external"
                              ? numeral(externalCoinAmount * amount1).format('$0,0.00')
                              : numeral(internalPrice * amount1).format('$0,0.00') || ""
                          }
                          className="form-control w-100 bg-transparent mb-3"
                        />
                      ) : (
                        ""
                      )}
                    </InputDiv>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <InputDiv>
                      <Label htmlFor="email">Commission Token</Label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <CustomSelect
                          name="commissionToken"
                          value={commissionToken}
                          onChange={(value) => {
                            setCommissionToken(value.value);
                          }}
                          options={tokens}
                        />
                        {errors.commissionToken && touched.commissionToken && (
                          <Error className="input feedback">
                            {errors.commissionToken}
                          </Error>
                        )}
                      </div>
                    </InputDiv>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <InputDiv>
                      <label htmlFor="email" className="mt-3">
                        From *
                      </label>
                      <ChatSearchInput
                        name="from"
                        type="text"
                        placeholder="Enter your from"
                        value={from1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.from && touched.from && "error"}
                        disabled
                        style={{
                          cursor: "not-allowed",
                          textTransform: "capitalize",
                        }}
                      />
                    </InputDiv>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <InputDiv>
                      <label htmlFor="email" className="mt-4">
                        Address *
                      </label>
                      <ChatSearchInput
                        name="address"
                        type="text"
                        disabled
                        placeholder="Enter your address"
                        value={
                          benficiaryToggle === "external" && account.address
                            ? account.address
                            : user1.data.user.address
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.address && touched.address && "error"}
                        style={{ cursor: "not-allowed" }}
                      />
                      {errors.from && touched.from && (
                        <Error className="input feedback">{errors.from}</Error>
                      )}
                    </InputDiv>
                  </div>
                  {benficiaryToggle === "external" ||
                    benficiaryToggle === "internal" ? (
                    <div className="col-md-4 col-lg-4 col-sm-12">
                      <InputDiv className="mt-5">
                        <FormControlLabel
                          control={
                            <Field
                              name="isBeneficiary"
                              component={CustomSwitch}
                              onChange={() => {
                                handleToggle();
                                setFieldValue("benficeryName", "");
                              }}
                              checked={toggle}
                            />
                          }
                          label="Manual Beneficiary"
                        />
                      </InputDiv>
                    </div>
                  ) : (
                    ""
                  )}
                  {toggle ? (
                    <><div className="col-md-4 col-lg-4 col-sm-12">
                      <InputDiv>
                        <Label htmlFor="email" className="mt-3">
                          Beneficiary Address *
                        </Label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <ChatSearchInput
                            name="benficeryAddress"
                            type="text"
                            // disabled
                            placeholder="Enter Benficery Address"
                            value={benficeryAddress}
                            onChange={(e) => setBenficeryAddress(e.target.value)}
                            onBlur={handleBlur}
                            className={errors.benficeryAddress &&
                              touched.benficeryAddress &&
                              "error"} />
                          {errors.benficeryAddress &&
                            touched.benficeryAddress && (
                              <Error className="input feedback">
                                {errors.benficeryAddress}
                              </Error>
                            )}
                        </div>
                      </InputDiv>
                    </div>
                      <div className="col-md-4 col-lg-4 col-sm-12">
                        <InputDiv>
                          <Label htmlFor="email" className="mt-3">
                            Beneficiary Docs
                          </Label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <ChatSearchInput
                              name="benficeryDocs"
                              type="text"
                              // disabled
                              placeholder="Enter Benficery Docs Address"
                              value={benficeryDocsAddress}
                              onChange={(e) => setBenficeryDocsAddress(e.target.value)}
                              onBlur={handleBlur} />

                          </div>
                        </InputDiv>
                      </div></>
                  ) : (
                    <>
                      <div className="col-md-4 col-lg-4 col-sm-12">
                        <InputDiv>
                          <Label htmlFor="email" className="mt-2">
                            Beneficiary Name *
                          </Label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <CustomSelect
                              isLoading={searchLoad}
                              name="benficeryName"
                              value={benficeryAddress}
                              onInputChange={handleInputChange}
                              placeholder={`${benficeryAddress
                                ? benficeryAddress
                                : "Search your Benficery Name"
                                }`}
                              onChange={(value) => {
                                setBenficeryName(value.value);
                                setBenficeryAddress(value.address);
                              }}
                              options={bnNames}
                              isSearchable
                            />{" "}
                            {errors.benficeryName && touched.benficeryName && (
                              <Error className="input feedback">
                                {errors.benficeryName}
                              </Error>
                            )}
                          </div>
                        </InputDiv>
                      </div>
                      <div className="col-md-4 col-lg-4 col-sm-12">
                        <InputDiv>
                          <Label htmlFor="email" className="mt-2">
                            Beneficiary Docs
                          </Label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <CustomSelect
                              isLoading={searchLoad}
                              name="benficeryDocs"
                              value={benficeryDocsAddress}
                              onInputChange={handleInputChange}
                              placeholder={`${benficeryDocsAddress
                                ? benficeryDocsAddress
                                : "Search your Benficery Docs "
                                }`}
                              onChange={(value) => {
                                setBenficeryDocsName(value.value);
                                setBenficeryDocsAddress(value.address);
                              }}
                              options={bnNames}
                              isSearchable
                            />{" "}

                          </div>
                        </InputDiv>
                      </div>
                      {/* <div className="col-md-4 col-lg-4 col-sm-12" hidden>
                        <InputDiv>
                          <Label htmlFor="email" className="mt-4">
                            Beneficiary Address *
                          </Label>
                          <ChatSearchInput
                            name="benficeryAddress"
                            type="text"
                            disabled
                            placeholder="Benficery Address"
                            value={benficeryAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // classAddress={errors.benficeryAddress && touched.benficeryAddress && 'error'}
                            style={{ cursor: "not-allowed" }}
                          />
                        </InputDiv>
                      </div> */}
                    </>
                  )}
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <InputDiv>
                      <label htmlFor="email" className="mt-4">
                        Date *
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <ChatSearchInput
                          name="date"
                          type="date"
                          min={minDate}
                          placeholder="Enter your date"
                          value={date1}
                          // onChange={handleChange}
                          onChange={(e) => setDate(e.target.value)}
                          onBlur={handleBlur}
                          className={errors.date && touched.date && "error"}
                        />
                        {errors.date && touched.date && (
                          <Error className="input feedback">
                            {errors.date}
                          </Error>
                        )}
                      </div>
                      {errors.upload && touched.upload && (
                        <Error className="input feedback">
                          {errors.upload}
                        </Error>
                      )}
                    </InputDiv>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <InputDiv>
                      <label htmlFor="email" className="mt-2">
                        File
                      </label>
                      <ChatSearchInput
                        style={{ display: "none" }}
                        type="file"
                        accept=".pdf"
                        onChange={(event) => {
                          const selectedFiles = Array.from(event.currentTarget.files); // Convert FileList to array
                          setFiles((prevFiles) => [...prevFiles, ...selectedFiles]); // Append new files to existing array
                        }}
                        ref={fileInput}
                        className={errors.upload && touched.upload && "error"}
                      />
                      <div style={{ width: "100%" }}>
                        <MediaButton
                          type="button"
                          className="py-2"
                          style={{ width: "100%" }}
                          onClick={() => fileInput.current.click()}
                        >
                          <span
                            className="lnr lnr-upload"
                            style={{ marginRight: "10px" }}
                          />
                          Upload
                        </MediaButton>
                      </div>
                      {errors.files && (
                        <Error className="input feedback">{errors.files}</Error>
                      )}
                    </InputDiv>
                  </div>

                  <div className="col-md-4 col-lg-4 col-sm-12">
                    <InputDiv>
                      <label htmlFor="email" className="mt-2">
                        Approver Name{" "}
                        {benficiaryToggle === "external" ? "*" : ""}{" "}
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <CustomSelect
                          name="approverName"
                          value={approverName}
                          onChange={(value) => {
                            setApproverName(value.value);
                            setApproverAddress(value.address);
                          }}
                          // options={approverData}
                          options={filterApproverData}
                          onInputChange={(val) => {
                            setApproverNameInput(val);
                          }}
                          placeholder={"Search your Approver Name"}
                        />
                        {errors.approverName && touched.approverName && (
                          <Error className="input feedback">
                            {errors.approverName}
                          </Error>
                        )}
                      </div>
                    </InputDiv>
                  </div>

                  <div className="d-flex flex-row overflow-x-scroll " style={{ scrollbarWidth: '0px' }}>
                    <div className="d-flex flex-column gap-2 w-100">
                      <div className="d-flex flex-row">
                        {files?.length > 0 &&
                          files.map((file, index) => (
                            <div key={index} className="col-md-4 col-lg-4 col-sm-12">
                              <label
                                htmlFor="features"
                                style={{ fontSize: "small" }}
                                className="form-label text-white mt-3"
                              >
                                Uploaded File{" "}
                              </label>
                              <br />
                              <div
                                className="py-0"
                                style={{
                                  border: "1px solid white",
                                  borderRadius: "4px",
                                }}
                              >
                                <label
                                  htmlFor="features"
                                  className="form-label text-white mt-1 mx-2"
                                >
                                  {file.name}{" "}
                                </label>
                                <button
                                  type="button"
                                  style={{ marginTop: "2%" }}
                                  className="btn btn-danger py-0 float-right mr-1 mb-0"
                                  onClick={() => handleFileRemove(index)} // Pass the index to remove
                                >
                                  Remove <i className="fa fa-file-pdf-o" />
                                </button>
                              </div>

                              {/* {files?.length > 0 &&
                        files.map((file, index) => ( */}
                              <div key={index} className="">
                                <LabelDiv>
                                  <label
                                    className="mt-3"
                                    style={{ color: "white" }}
                                    htmlFor="email"
                                  >
                                    Signature here
                                  </label>
                                </LabelDiv>
                                <div
                                  style={{
                                    border: "2px solid white",
                                    borderRadius: "6px",
                                    background: "white",
                                  }}
                                >
                                  <SignaturePad
                                    getPDf1={getPDf1}
                                    onEnd={handleSignatureEnd}
                                  />
                                  {signatureImage1 && (
                                    <>
                                      <PdfSignature
                                        pdfFile={file} // Pass the current file to PdfSignature
                                        signatureImage={signatureImage1}
                                        getPDf={getPDf}
                                      />
                                    </>
                                  )}
                                </div>
                                {errors.signature && (
                                  <Error className="input feedback">
                                    {errors.signature}
                                  </Error>
                                )}
                              </div>
                              {/* ))
                      } */}
                            </div>
                          ))
                        }

                      </div>


                    </div>
                  </div>




                  <div className="col-md-4 col-lg-4 col-sm-12" hidden>
                    <InputDiv>
                      <label htmlFor="email" className="mt-4">
                        Approver Address{" "}
                        {benficiaryToggle === "external" ? "*" : ""}{" "}
                      </label>
                      <ChatSearchInput
                        name="approverAddress"
                        type="text"
                        disabled
                        placeholder="Approver Address"
                        value={approverAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // classAddress={errors.approverAddress && touched.approverAddress && 'error'}
                        style={{ cursor: "not-allowed" }}
                      />
                    </InputDiv>
                  </div>


                  {userSessionData.data.user.TFA && (
                    <div className=" col-lg-3 col-md-12 col-sm-12 mt-1">
                      <InputDiv>
                        <label htmlFor="email" className="text-white mb-3">
                          Verify TFA *
                        </label>
                        <ChatSearchInput
                          name="tfa"
                          type="text"
                          placeholder="Enter Code"
                          value={tfaCode}
                          onChange={(e) => {
                            setTfaCode(e.target.value);
                          }}
                        />

                      </InputDiv>

                      <InputDiv>
                        <FormButton
                          variant="outline-white"
                          className="py-2 mt-4 w-100 btn-sm"
                          onClick={handleTfaCode}
                          disabled={isTfaVarified}
                        >
                          {isTfaVarified ? "Verified" : "Verify"}
                        </FormButton>

                      </InputDiv>

                    </div>
                  )}
                  {otp && (
                    <div className="col-md-4 col-lg-4 col-sm-12">
                      <InputDiv>
                        <Label htmlFor="email" className="mt-3">
                          OTP *
                        </Label>
                        <div style={{ width: "100%" }}>
                          <ChatSearchInput
                            name="otp"
                            type="text"
                            placeholder="Enter your otp"
                            style={{ width: "100%" }}
                            value={otp1}
                            // onChange={handleChange}
                            onChange={(e) => setOtp1(e.target.value)}
                            onBlur={handleBlur}
                            className={errors.otp && touched.otp && "error"}
                          />
                          {errors.otp && touched.otp && (
                            <Error className="input feedback">
                              {errors.otp}
                            </Error>
                          )}
                        </div>
                      </InputDiv>
                    </div>
                  )}
                </div>
                {otpbtn && (
                  <div className="row mb-1">
                    <div className="col-md-4 col-lg-4 col-sm-12 mx-auto">
                      {isError ? (
                        <FormButton
                          className="mt-3"
                          type="submit"
                          variant="primary"
                          disabled
                        >
                          Add Contract
                        </FormButton>
                      ) : isLoading ? (
                        <FormButton
                          type="submit"
                          className="mt-4 w-100"
                          variant="outline-success"
                          disabled={otp && otp1 === ""}
                        >
                          Processing....
                        </FormButton>
                      ) : (
                        <FormButton
                          type="submit"
                          className="mt-4 w-100"
                          variant="outline-success"
                          disabled={otp && otp1 === "" || (userSessionData.data.user.TFA && !isTfaVarified)}
                        >
                          Add Contract
                        </FormButton>
                      )}
                    </div>
                  </div>
                )}
                {(seconds > 0 || minutes > 0) && count < 3 && (
                  <span style={{ color: colorAccent }}>
                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </span>
                )}
                {resendOtp && count < 3 && (
                  <div className="row mb-1">
                    <div className="col-md-4 col-lg-4 col-sm-12 mx-auto">
                      <FormButton
                        variant="primary-outline"
                        className="mt-4 w-100"
                        disabled={seconds > 0 || minutes > 0}
                        onClick={() => {
                          ResendOtp();
                        }}
                      >
                        Resend Otp
                      </FormButton>
                    </div>
                  </div>
                )}
                <div className="row mb-1">
                  <div className="col-md-4 col-lg-4 col-sm-12 mx-auto">
                    <Tfaaa
                      tfaUrl={tfaUrl}
                      tfaEmail={tfaEmail}
                      onDataFromChild={handleDataFromChild}
                    />
                  </div>
                </div>
                {/* {msg !== '' && (<span style={{ color: statusCode === 200 ? 'green' : 'red' }}>{msg}</span>)} */}
              </form>
            );
          }}
        </Formik>
        <SimpleModal isActive={IsBtc} onClose={() => setIsBtc(false)}>
          <InputDiv>
            <Grid>
              <Label htmlFor="email">Add BTC wallet Address</Label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <ChatSearchInput
                  name="name"
                  type="text"
                  placeholder="Enter your address"
                  value={btcAddress}
                  onChange={handleBtcChange}
                // onBlur={handleBtcChange}
                // className={errors.name && touched.name && 'error'}
                />
                {validBtcAddress === false && (
                  <Error className="input feedback">
                    {validBtcAddress === false
                      ? "Invalid BTC Address"
                      : "Valid BTC Address"}
                  </Error>
                )}
              </div>
            </Grid>
          </InputDiv>
          <InputDiv>
            <Grid>
              <Label />
              {validBtcAddress ? (
                <Button
                  className="w-25"
                  onClick={() => {
                    fetchBalance();
                  }}
                >
                  Submit
                </Button>
              ) : (
                <Button className="w-25" disabled>
                  Submit
                </Button>
              )}
            </Grid>
          </InputDiv>
        </SimpleModal>
      </Panel>
    </>
  );
};

export default ContractForm;

const CustomSelectCustom = ({
  isLoading,
  onChange,
  options,
  value,
  name,
  onClick,
  handleBlur,
  isDisabled,
  isSearchable,
  onInputChange,
  placeholder,
}) => {
  const style = {
    option: (provided, state) => {
      const styles = {
        ...provided,
        cursor: "pointer",
      };

      if (state.isSelected) {
        styles.color = "#fff";
        styles.backgroundColor = "#4ce1b6";
      } else if (state.isDisabled) {
        styles.color = "#A9A9A9";
        styles.backgroundColor = "#857f7f";
      } else {
        styles.color = "black";
        styles.backgroundColor = "#fff";
      }

      styles["&:hover"] = {
        backgroundColor: "#4ce1b6",
      };

      return styles;
    },
    control: (base) => ({
      ...base,
      width: "100%",
      border: "1px solid #b1b1b1 !important",
      backgroundColor: "transparent !important",
      "&:hover": {
        border: "1px solid #4ce1b6 !important",
        backgroundColor: "transparent !important",
      },
      ".isDisabled": {
        border: "1px solid #4ce1b6 !important",
        backgroundColor: "transparent !important",
      },
    }),
    singleValue: (provided, state) => {
      const styles = {
        ...provided,
        color: "#b1b1b1", // Change this to the color you want
      };

      return styles;
    },
  };
  // eslint-disable-next-line max-len
  const defaultValue = (selectOptions, selectValue) =>
    selectOptions
      ? selectOptions?.find((option) => option.value === selectValue)
      : null;
  return (
    <Select
      key={`my_unique_select_key__${value}`}
      isLoading={isLoading}
      value={defaultValue(options, value) || ""}
      // eslint-disable-next-line no-shadow
      onChange={onChange}
      name={name}
      options={options}
      className="selectDropdpenReact"
      onClick={onClick}
      width="100%"
      styles={style}
      minMenuHeight={100}
      maxMenuHeight={150}
      menuPlacement="bottom"
      onBlur={handleBlur}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      onInputChange={onInputChange}
      placeholder={placeholder}
    />
  );
};

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;

const Label = styled.label`
        @media screen and (max-width: 876px) {
          margin - bottom: 0px;
        margin-top: 8px;
}
        `;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 15rem 20rem 20rem;
  grid-gap: 1rem;
  width: 100%;
  @media screen and (max-width: 876px) {
    grid-template: none;
  }
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
  align-items: flex-start;
`;
const Error = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
  width: 100%;
  text-align: left;
  cursor: none;
`;

const LabelDiv = styled.div`
  width: 100%;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

const FormButton = styled(Button)`
  color: "white";
  margin-top: 8px;
`;
const MediaButton = styled(Button)`
  color: "white";
  margin-top: 0px;
  padding: 3px 10px;
  margin-bottom: 0px;
`;
const getMainColor = (color) => {
  switch (color) {
    case "success":
      return "#c1f9c1";
    case "danger":
      return "#f1beb0";
    default:
      return "#f1beb0";
  }
};

const getLightColor = (color) => {
  switch (color) {
    case "success":
      return "#22bb33";
    case "danger":
      return "#ff4861";
    default:
      return "#f88e7d";
  }
};

const StyledAlert = styled(Alert).withConfig({
  shouldForwardProp: (prop) =>
    !["bordered", "neutral", "colored"].includes(prop),
})`
  background-color: ${(props) => getMainColor(props.variant)};
  border-color: ${(props) => getMainColor(props.variant)};
  color: ${(props) => getLightColor(props.variant)};
`;
