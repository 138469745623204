import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { right, left, marginRight } from '@/utils/directions';
import { CRYPTOCURRENCIES_NAMES_COINCAP } from '@/shared/constants/cryptocurrencies';
import { fetchTopTenCurrencies } from '@/redux/actions/topTenActions';

const Whitepaper = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const whitepaper = `${process.env.PUBLIC_URL}/img/logo/whitepaper.png`;

  useEffect(() => {
    dispatch(fetchTopTenCurrencies());
  }, [dispatch]);


  return (
    <Container>
      <Row className="mx-0">
        <Col xs={12} md={7} lg={7} />
        <Col xs={12} md={4} lg={3} className="text-center">
          <a 
            href="https://app-dev.custodiy.com/assets/Custodiy_WP.pdf"
            target="_blank" 
            rel="noreferrer" 
            className="btn btn-primary purple-button btn-sm shadow-none mb-2 text-white w-100 py-2"
          >
            Download <i className="fa fa-download" />
          </a>
        </Col>
        <Col xs={12} md={1} lg={2} />
      </Row>
      <Row className="mx-0 mb-4">
        <Col xs={12} md={1} lg={2} />
        <Col xs={12} md={10} lg={8}>
          <ProductItemLabel src={whitepaper} alt="new" />
        </Col>
        <Col xs={12} md={1} lg={2} />
      </Row>
    </Container>
  );
};

export default Whitepaper;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const Error = styled.div`
 
color: #e66e6e;
padding: 4px 0px;
font-size: 14px;
cursor:none;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

const ProductItemLabel = styled.img`
  border-radius: '10%'
`;

const ProductItemLabel1 = styled.img`
  position: absolute;
  top: -10px;
  ${right}: 5px;
  width: 65px;
`;
