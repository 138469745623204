import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "@/shared/components/Button";
import { Ring } from "@uiball/loaders";
import { countries, countryCodes } from "../../../utils/constants/contants";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import { createUserOnStriga } from "../../../utils/services/user.service";
import { toast } from "react-toastify";

const StrigaWalletDetailModal = ({ show, hide, data }) => {
    console.log("🚀 ~ StrigaWalletDetailModal ~ data:", data)

    const [isLoading, setIsLoading] = useState(false);
    const accountsArray = Object.keys(data.accounts).map(key => ({
        currency: key,
        ...data.accounts[key]
    }));

    const getLogoUrl = (currency) => {
        const logs = [
            { BTC: "https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=032" },
            { EUR: "https://cryptologos.cc/logos/stasis-euro-eurs-logo.png?v=032" },
            { USDC: "https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=032" },
            { USDT: "https://cryptologos.cc/logos/tether-usdt-logo.png?v=032" },
            { ETH: "https://cryptologos.cc/logos/ethereum-classic-etc-logo.png?v=032" },
            { BNB: "https://cryptologos.cc/logos/bnb-bnb-logo.png?v=032" }
        ];

        // Convert logs array into an object for quicker lookups
        const logos = logs.reduce((acc, log) => {
            const key = Object.keys(log)[0];
            acc[key] = log[key];
            return acc;
        }, {});

        return logos[currency] || ''; // Return the URL or an empty string if not found
    };
    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={hide}
            >
                <Modal.Header closeButton>
                    <h4 className="text-black">Wallet Details</h4>
                </Modal.Header>
                <Modal.Body>

                    <div className="d-flex flex-wrap ">
                        {
                            accountsArray?.map((item, idx) => {
                                // Get the logo URL for the current currency
                                const logoUrl = getLogoUrl(item?.currency);
                                return (
                                    <div className="col-4 py-4 d-flex flex-column" key={idx}>
                                        <StyledDivInfo>
                                            <div className="d-flex gap-2 align-items-center">
                                                <img src={logoUrl} alt={`${item?.currency} logo`} style={{
                                                    width:'42px',
                                                    height:"42px"
                                                }}
                                                
                                                />
                                                <p>{item?.currency}</p>
                                            </div>
                                            <p>{item?.availableBalance?.amount}</p>
                                            <p>{'Account ID: '}{item?.accountId}</p>
                                        </StyledDivInfo>
                                    </div>
                                );
                            })
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default StrigaWalletDetailModal;

const FormButton = styled(Button)`
  color: white;
  margin-top: 1rem;
`;

const StyledDivInfo = styled.div`
  background-color: rgb(27, 27, 27);
  display:flex;
  flex-direction: column;
  gap:10px;
  border-radius: 18px;
  color: rgb(155, 155, 155);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 16px;
  position: relative;
  width: 100%;
  cursor:pointer;
  @media only screen and (max-width: 768px) {
    width: 95%;
    padding: 12px;
  }
`;
